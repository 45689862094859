import React from 'react';

// react function component template
export default function Wordmark(props) {
    switch (props.type) {
        case 'raspored':
            return (
                <svg width="146" height="37" preserveAspectRatio={props.preserveAspectRatio ? null : "xMinYMin slice"} viewBox="0 0 146 37" style={{ ...((props.hidden || false) ? { width: '37px', filter: 'contrast(1)', opacity: '.5', transform: 'scale(90%)' } : {}), ...props.style }} xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.5789 15.4475H23.4032V32.375H15.5789V15.4475ZM26.5329 32.375H31.2274C32.9488 32.375 34.3571 30.9875 34.3571 29.2917V15.4167H26.5329V32.375ZM31.2274 4.625H7.7547C6.03336 4.625 4.625 6.0125 4.625 7.70833V12.3333H34.3571V7.70833C34.3571 6.0125 32.9488 4.625 31.2274 4.625ZM4.625 29.2917C4.625 30.9875 6.03336 32.375 7.7547 32.375H12.4492V15.4167H4.625V29.2917Z" />
                    <path d="M54.3908 20.2763L59.3805 26.4289H55.9976L50.8599 19.7478V26.4289H48.1113V11.6289H54.2005C55.1589 11.6289 56.0047 11.8121 56.7376 12.1786C57.4283 12.531 57.985 13.0455 58.4079 13.7221C58.8026 14.3704 58.9999 15.1316 58.9999 16.0055C58.9999 17.26 58.5982 18.2748 57.7948 19.0501C56.9772 19.8253 55.8426 20.2341 54.3908 20.2763ZM53.6508 13.9969H50.8599V18.5215H53.3548C54.2428 18.5215 54.9334 18.3171 55.4268 17.9083C55.8919 17.4996 56.1245 16.9358 56.1245 16.2169C56.1245 15.5262 55.913 14.9765 55.4902 14.5678C55.0673 14.1872 54.4542 13.9969 53.6508 13.9969Z" />
                    <path d="M65.2242 15.4769C66.7323 15.4769 67.9163 15.8927 68.7762 16.7243C69.6219 17.556 70.0447 18.7047 70.0447 20.1706V26.4289H67.4653V25.0758C67.1975 25.5832 66.8028 25.992 66.2813 26.3021C65.7598 26.5981 65.1537 26.7461 64.463 26.7461C63.7583 26.7461 63.1381 26.591 62.6024 26.2809C62.0527 25.9849 61.6228 25.5762 61.3127 25.0546C61.0026 24.5331 60.8476 23.9482 60.8476 23.2998C60.8476 22.2708 61.207 21.4392 61.9259 20.8049C62.6165 20.1706 63.5398 19.8535 64.6956 19.8535C65.3158 19.8535 65.8655 19.9592 66.3447 20.1706C66.824 20.4102 67.1975 20.7133 67.4653 21.0798V20.4243C67.4653 19.5645 67.2257 18.895 66.7464 18.4158C66.2531 17.9506 65.5765 17.7181 64.7167 17.7181C63.4482 17.7181 62.3487 18.1621 61.4184 19.0501V16.6609C62.5743 15.8716 63.8428 15.4769 65.2242 15.4769ZM65.4144 24.8855C66.0205 24.8855 66.5209 24.7234 66.9156 24.3992C67.2962 24.0891 67.4864 23.6662 67.4864 23.1306C67.4864 22.6514 67.3032 22.2497 66.9367 21.9255C66.5703 21.6154 66.0699 21.4603 65.4356 21.4603C64.7872 21.4603 64.2868 21.6154 63.9344 21.9255C63.568 22.2497 63.3847 22.6655 63.3847 23.1729C63.3847 23.6944 63.5609 24.1102 63.9133 24.4203C64.2657 24.7304 64.7661 24.8855 65.4144 24.8855Z" />
                    <path d="M76.3683 26.7461C74.8319 26.7461 73.4858 26.3232 72.33 25.4775V23.2363C72.8515 23.6592 73.4576 24.0045 74.1483 24.2723C74.8107 24.5261 75.4591 24.6529 76.0934 24.6529C76.6995 24.6529 77.1576 24.5401 77.4677 24.3146C77.7778 24.1032 77.9328 23.7861 77.9328 23.3632C77.9328 23.0531 77.8412 22.7994 77.658 22.6021C77.4465 22.3906 77.1364 22.2285 76.7277 22.1158L74.9728 21.6083C73.2109 21.115 72.33 20.1354 72.33 18.6695C72.33 17.711 72.7105 16.9358 73.4717 16.3438C74.2187 15.7659 75.2265 15.4769 76.4951 15.4769C77.7919 15.4769 78.9759 15.8152 80.0471 16.4918V18.6906C79.5538 18.3101 79.0252 18.0282 78.4614 17.8449C77.8976 17.6617 77.2985 17.5701 76.6643 17.5701C75.5366 17.5701 74.9728 17.9083 74.9728 18.5849C74.9728 19.1628 75.3605 19.5434 76.1357 19.7266L77.8694 20.1706C79.7018 20.6499 80.618 21.6859 80.618 23.2786C80.618 24.3076 80.2304 25.1392 79.4551 25.7735C78.6799 26.4219 77.6509 26.7461 76.3683 26.7461Z" />
                    <path d="M89.0472 15.4769C89.9634 15.4769 90.7668 15.7165 91.4575 16.1958C92.1623 16.6891 92.712 17.3516 93.1066 18.1832C93.4872 19.0289 93.6775 19.9733 93.6775 21.0163C93.6775 22.144 93.4661 23.1377 93.0432 23.9975C92.5922 24.8714 92.0072 25.548 91.2883 26.0272C90.5554 26.5064 89.7379 26.7461 88.8358 26.7461C88.0746 26.7461 87.4122 26.5769 86.8483 26.2386C86.2563 25.9144 85.7912 25.4704 85.4529 24.9066V30.4461H82.8312V15.7941H85.4318V17.7815C85.7419 17.1331 86.2141 16.5763 86.8483 16.1112C87.4685 15.6883 88.2015 15.4769 89.0472 15.4769ZM88.1381 24.4838C88.9979 24.4838 89.6885 24.1666 90.2101 23.5323C90.7316 22.9262 90.9923 22.1228 90.9923 21.1221C90.9923 20.1213 90.7386 19.3179 90.2312 18.7118C89.6956 18.0916 89.019 17.7815 88.2015 17.7815C87.3558 17.7815 86.6792 18.0986 86.1718 18.7329C85.6643 19.3672 85.4106 20.1847 85.4106 21.1855C85.4106 22.1581 85.6573 22.9544 86.1506 23.5746C86.6299 24.1807 87.2923 24.4838 88.1381 24.4838Z" />
                    <path d="M100.72 26.7461C99.6629 26.7461 98.7256 26.5064 97.908 26.0272C97.0623 25.5198 96.421 24.8573 95.984 24.0398C95.5189 23.1941 95.2863 22.2285 95.2863 21.1432C95.2863 20.0438 95.5189 19.0642 95.984 18.2043C96.4069 17.3727 97.0482 16.7032 97.908 16.1958C98.7256 15.7165 99.6629 15.4769 100.72 15.4769C101.805 15.4769 102.757 15.7165 103.574 16.1958C104.406 16.6891 105.047 17.3586 105.498 18.2043C105.949 19.0782 106.175 20.0579 106.175 21.1432C106.175 22.2144 105.949 23.18 105.498 24.0398C105.047 24.8714 104.399 25.5339 103.553 26.0272C102.736 26.5064 101.791 26.7461 100.72 26.7461ZM100.72 24.4626C101.58 24.4626 102.263 24.1525 102.771 23.5323C103.264 22.9121 103.511 22.1087 103.511 21.1221C103.511 20.1213 103.264 19.3108 102.771 18.6906C102.263 18.0704 101.58 17.7603 100.72 17.7603C99.8602 17.7603 99.1907 18.0634 98.7115 18.6695C98.2181 19.2897 97.9715 20.1072 97.9715 21.1221C97.9715 22.1087 98.2181 22.9121 98.7115 23.5323C99.2048 24.1525 99.8743 24.4626 100.72 24.4626Z" />
                    <path d="M113.697 15.6038C114.219 15.6038 114.677 15.7588 115.072 16.0689V18.5426C114.621 18.2184 114.057 18.0563 113.38 18.0563C112.69 18.0563 112.14 18.3241 111.731 18.8598C111.337 19.3813 111.125 20.1283 111.097 21.1009V26.4289H108.475V15.7941H111.097V17.9083C111.351 17.1613 111.703 16.5834 112.154 16.1746C112.605 15.7941 113.12 15.6038 113.697 15.6038Z" />
                    <path d="M126.434 20.2975V21.6929H118.928C119.055 22.6232 119.443 23.328 120.091 23.8072C120.74 24.3005 121.6 24.5472 122.671 24.5472C123.263 24.5472 123.869 24.4485 124.489 24.2512C125.081 24.068 125.588 23.8142 126.011 23.4901V25.6255C125.574 25.9779 125.025 26.2457 124.362 26.4289C123.686 26.6403 122.953 26.7461 122.163 26.7461C120.979 26.7461 119.943 26.5135 119.055 26.0483C118.167 25.5973 117.477 24.9348 116.983 24.0609C116.49 23.2011 116.243 22.2003 116.243 21.0586C116.243 19.9592 116.462 18.9937 116.899 18.1621C117.322 17.3163 117.942 16.6539 118.759 16.1746C119.577 15.7095 120.542 15.4769 121.656 15.4769C123.122 15.4769 124.292 15.8998 125.166 16.7455C126.011 17.5912 126.434 18.7752 126.434 20.2975ZM121.656 17.6123C120.895 17.6123 120.289 17.8238 119.838 18.2466C119.372 18.7118 119.091 19.3108 118.992 20.0438H124.066C124.038 19.2685 123.82 18.6695 123.411 18.2466C122.974 17.8238 122.389 17.6123 121.656 17.6123Z" />
                    <path d="M136.341 17.5066V11.2061H138.962V26.4289H136.362V24.4626C136.038 25.1533 135.587 25.703 135.009 26.1118C134.403 26.5346 133.655 26.7461 132.767 26.7461C131.823 26.7461 131.006 26.5064 130.315 26.0272C129.596 25.5339 129.053 24.8714 128.687 24.0398C128.306 23.1941 128.116 22.2356 128.116 21.1643C128.116 20.1072 128.327 19.1417 128.75 18.2678C129.145 17.4221 129.723 16.7384 130.484 16.2169C131.231 15.7236 132.07 15.4769 133 15.4769C134.508 15.4769 135.622 16.1535 136.341 17.5066ZM133.529 24.4626C134.388 24.4626 135.079 24.1455 135.601 23.5112C136.122 22.9051 136.383 22.0946 136.383 21.0798C136.383 20.0931 136.136 19.2897 135.643 18.6695C135.135 18.0634 134.452 17.7603 133.592 17.7603C132.746 17.7603 132.07 18.0704 131.562 18.6906C131.055 19.3108 130.801 20.1354 130.801 21.1643C130.801 22.1651 131.055 22.9615 131.562 23.5535C132.042 24.1596 132.697 24.4626 133.529 24.4626Z" />
                </svg>
            );
        case 'ocjene':
            return (
                <svg width="116" height="37" preserveAspectRatio={props.preserveAspectRatio ? null : "xMinYMin slice"} viewBox="0 0 116 37" style={{ ...((props.hidden || false) ? { width: '37px', filter: 'contrast(1)', opacity: '.5', transform: 'scale(90%)' } : {}), ...props.style }} xmlns="http://www.w3.org/2000/svg">
                    <path d="M55.8232 25.5435C54.7229 26.1876 53.4817 26.5097 52.0996 26.5097C50.7175 26.5097 49.483 26.1876 48.3961 25.5435C47.2958 24.9263 46.4438 24.0407 45.8399 22.8867C45.2227 21.773 44.9141 20.4982 44.9141 19.0625C44.9141 17.6535 45.2227 16.3788 45.8399 15.2382C46.4438 14.1111 47.2958 13.2254 48.3961 12.5814C49.483 11.9373 50.7175 11.6152 52.0996 11.6152C53.4817 11.6152 54.7229 11.9373 55.8232 12.5814C56.8967 13.2254 57.7488 14.1111 58.3794 15.2382C58.9833 16.3788 59.2852 17.6535 59.2852 19.0625C59.2852 20.4848 58.9833 21.7596 58.3794 22.8867C57.7488 24.0407 56.8967 24.9263 55.8232 25.5435ZM52.0996 24.034C52.9852 24.034 53.7769 23.8193 54.4747 23.3899C55.1456 22.9605 55.6622 22.3701 56.0245 21.6187C56.3868 20.8806 56.5679 20.0353 56.5679 19.0826C56.5679 18.1299 56.3868 17.2778 56.0245 16.5264C55.6622 15.7749 55.1456 15.1778 54.4747 14.735C53.7769 14.3056 52.9852 14.0909 52.0996 14.0909C51.2006 14.0909 50.4156 14.3056 49.7447 14.735C49.0738 15.1778 48.5572 15.7749 48.1949 16.5264C47.8326 17.2778 47.6514 18.1299 47.6514 19.0826C47.6514 20.0353 47.8326 20.8806 48.1949 21.6187C48.5572 22.3701 49.0738 22.9605 49.7447 23.3899C50.4156 23.8193 51.2006 24.034 52.0996 24.034Z" />
                    <path d="M66.3652 26.409C65.3052 26.409 64.3592 26.1742 63.5272 25.7046C62.6819 25.2349 62.0445 24.5975 61.6151 23.7924C61.1589 22.9739 60.9308 22.0548 60.9308 21.035C60.9308 20.0017 61.1589 19.0759 61.6151 18.2573C62.0445 17.4657 62.6819 16.835 63.5272 16.3654C64.3457 15.9091 65.3052 15.681 66.4055 15.681C67.6668 15.681 68.7403 15.9427 69.6259 16.466V18.8813C68.794 18.2104 67.8211 17.8749 66.7074 17.8749C65.7278 17.8749 64.9496 18.1634 64.3726 18.7404C63.7822 19.3308 63.487 20.089 63.487 21.0148C63.487 21.9675 63.7822 22.7391 64.3726 23.3295C64.963 23.9199 65.7949 24.2151 66.8684 24.2151C67.432 24.2151 67.9553 24.1279 68.4384 23.9535C68.9751 23.7522 69.4045 23.5241 69.7265 23.2691V25.5033C68.7872 26.1071 67.6668 26.409 66.3652 26.409Z" />
                    <path d="M73.3424 14.4532C72.9265 14.4532 72.5709 14.319 72.2757 14.0507C71.9805 13.7957 71.8329 13.4536 71.8329 13.0242C71.8329 12.6082 71.9805 12.2727 72.2757 12.0178C72.5709 11.7494 72.9265 11.6152 73.3424 11.6152C73.745 11.6152 74.0939 11.7494 74.3891 12.0178C74.6708 12.2862 74.8117 12.6216 74.8117 13.0242C74.8117 13.4536 74.6708 13.7957 74.3891 14.0507C74.0939 14.319 73.745 14.4532 73.3424 14.4532ZM71.4303 30.2534C70.652 30.2534 70.0281 30.079 69.5584 29.7301V27.7576C69.7195 27.8783 69.914 27.9723 70.1421 28.0394C70.3702 28.1333 70.6319 28.1803 70.9271 28.1803C71.7054 28.1803 72.0945 27.7039 72.0945 26.7512V15.9829H74.5903V27.1538C74.5903 28.0796 74.3018 28.8243 73.7248 29.3879C73.1479 29.9649 72.383 30.2534 71.4303 30.2534Z" />
                    <path d="M86.5142 20.2701V21.5985H79.3689C79.4897 22.4841 79.8587 23.1551 80.4759 23.6113C81.0932 24.0809 81.9117 24.3158 82.9315 24.3158C83.4951 24.3158 84.0721 24.2218 84.6625 24.034C85.226 23.8595 85.7091 23.618 86.1117 23.3094V25.3423C85.6957 25.6777 85.1724 25.9327 84.5417 26.1071C83.8976 26.3084 83.1999 26.409 82.4484 26.409C81.3213 26.409 80.335 26.1876 79.4897 25.7448C78.6443 25.3154 77.9868 24.6848 77.5172 23.8528C77.0475 23.0343 76.8127 22.0816 76.8127 20.9947C76.8127 19.9481 77.0207 19.0289 77.4367 18.2372C77.8392 17.4321 78.4296 16.8014 79.2079 16.3452C79.9862 15.9024 80.9053 15.681 81.9654 15.681C83.3609 15.681 84.4746 16.0836 85.3066 16.8887C86.1117 17.6938 86.5142 18.8209 86.5142 20.2701ZM81.9654 17.7139C81.2408 17.7139 80.6638 17.9152 80.2344 18.3177C79.7916 18.7605 79.5232 19.3308 79.4293 20.0286H84.2599C84.2331 19.2906 84.0251 18.7203 83.636 18.3177C83.22 17.9152 82.6631 17.7139 81.9654 17.7139Z" />
                    <path d="M94.4958 15.681C95.5826 15.681 96.4414 16.0366 97.0721 16.7478C97.7028 17.4858 98.0181 18.4922 98.0181 19.7669V26.1071H95.5223V20.3104C95.5223 19.465 95.3679 18.8343 95.0593 18.4184C94.7373 18.0158 94.2341 17.8145 93.5498 17.8145C92.8117 17.8145 92.2415 18.0628 91.8389 18.5593C91.4364 19.0557 91.2351 19.7602 91.2351 20.6727V26.1071H88.7393V15.9829H91.215V17.5931C91.4967 17.0027 91.9194 16.5331 92.483 16.1842C93.0331 15.8487 93.7041 15.681 94.4958 15.681Z" />
                    <path d="M109.767 20.2701V21.5985H102.622C102.743 22.4841 103.112 23.1551 103.729 23.6113C104.346 24.0809 105.165 24.3158 106.184 24.3158C106.748 24.3158 107.325 24.2218 107.915 24.034C108.479 23.8595 108.962 23.618 109.365 23.3094V25.3423C108.949 25.6777 108.425 25.9327 107.795 26.1071C107.151 26.3084 106.453 26.409 105.701 26.409C104.574 26.409 103.588 26.1876 102.743 25.7448C101.897 25.3154 101.24 24.6848 100.77 23.8528C100.3 23.0343 100.066 22.0816 100.066 20.9947C100.066 19.9481 100.274 19.0289 100.69 18.2372C101.092 17.4321 101.683 16.8014 102.461 16.3452C103.239 15.9024 104.158 15.681 105.218 15.681C106.614 15.681 107.728 16.0836 108.559 16.8887C109.365 17.6938 109.767 18.8209 109.767 20.2701ZM105.218 17.7139C104.494 17.7139 103.917 17.9152 103.487 18.3177C103.044 18.7605 102.776 19.3308 102.682 20.0286H107.513C107.486 19.2906 107.278 18.7203 106.889 18.3177C106.473 17.9152 105.916 17.7139 105.218 17.7139Z" />
                    <path d="M13.2716 31.1614C12.2323 31.9495 11.1786 31.9698 10.1104 31.2221C9.04218 30.4744 8.71372 29.4892 9.12496 28.2666L11.0544 22.0287L6.21012 18.6034C5.14193 17.8414 4.79967 16.849 5.18332 15.6264C5.56697 14.4024 6.41574 13.7904 7.72962 13.7904H13.6835L15.6937 7.22567C15.8855 6.57324 16.2212 6.09762 16.7008 5.79881C17.179 5.49999 17.6921 5.35059 18.24 5.35059C18.7879 5.35059 19.301 5.49999 19.7792 5.79881C20.2588 6.09762 20.5945 6.57324 20.7863 7.22567L22.7965 13.7904H28.7504C30.0642 13.7904 30.913 14.4024 31.2967 15.6264C31.6803 16.849 31.3381 17.8414 30.2699 18.6034L25.4256 22.0287L27.355 28.2255C27.7663 29.4494 27.4378 30.4352 26.3696 31.1829C25.3014 31.9306 24.2477 31.9097 23.2084 31.1203L18.2814 27.4112L13.2716 31.1614Z" />
                </svg>
            );
        default:
            return <p>oops</p>;
    }
}