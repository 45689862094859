import Themes from '../backend/configuration/Themes';

const sortThemesByOwnership = (userInventory, reverseOutput = false) => {
    var themesOwned = userInventory.map((item) => item.value); // maps all themes owned by the user to an array

    // adds the owned property to all themes
    var modifiedThemes = Object.values(Themes).map((theme, index) => { // adds the owned and slug properties to all themes
        return { ...theme, owned: themesOwned.includes(Object.keys(Themes)[index]), slug: Object.keys(Themes)[index] };
    });

    // combines the two arrays, in order that's provided
    if (reverseOutput) return modifiedThemes.filter((theme) => theme.owned).concat(modifiedThemes.filter((theme) => !theme.owned && !theme.hidden));
    else return modifiedThemes.filter((theme) => !theme.owned && !theme.hidden).concat(modifiedThemes.filter((theme) => theme.owned));
};
export default sortThemesByOwnership;