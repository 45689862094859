import React from 'react';

import '../../stylesheet/periods.css';

export default class BreakIcon extends React.Component {
    render() {
        return (
            // <div className='footer-button' onClick={this.props.onClick || null}>
            <svg className='period-break-icon' width={this.props.size || '20'} height={this.props.size || '20'} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"> 

                {(() => {
                    switch (this.props.type) {
                        case 'SHORT_BREAK':
                            return (
                                <>
                                    <path d="M5 15.8337H8.33333V4.16699H5V15.8337ZM11.6667 4.16699V15.8337H15V4.16699H11.6667Z" />
                                </>
                            );
                        case 'LONG_BREAK':
                            return (
                                <>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M17.3674 8.67839C17.6007 6.2869 14.2468 4.30371 10.0836 4.30371C5.92035 4.30371 2.56642 6.2869 2.79974 8.67839H17.3674Z" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.22769 11.2303C5.6287 11.2303 5.7964 11.3324 6.06617 11.4928C6.39427 11.6896 6.84632 11.9594 7.65564 11.9594C8.46495 11.9594 8.917 11.6896 9.2451 11.4928C9.51488 11.3251 9.67528 11.2303 10.0836 11.2303C10.4846 11.2303 10.6523 11.3324 10.9221 11.4928C11.2502 11.6896 11.7022 11.9594 12.5115 11.9594C13.3209 11.9594 13.7729 11.6896 14.101 11.4928C14.3708 11.3251 14.5312 11.2303 14.9395 11.2303C15.3405 11.2303 15.5082 11.3324 15.778 11.4928C16.1061 11.6896 16.5581 11.9521 17.3601 11.9594V10.5158C17.3601 10.5158 16.7841 10.3991 16.5144 10.2387C16.1863 10.0418 15.7342 9.77207 14.9249 9.77207C14.1156 9.77207 13.6635 10.0418 13.3354 10.2387C13.0657 10.4064 12.898 10.5012 12.497 10.5012C12.0959 10.5012 11.9282 10.3991 11.6585 10.2387C11.3304 10.0418 10.8783 9.77207 10.069 9.77207C9.25969 9.77207 8.80764 10.0418 8.47954 10.2387C8.20976 10.4064 8.04936 10.5012 7.64105 10.5012C7.24004 10.5012 7.07235 10.3991 6.80257 10.2387C6.47447 10.0418 6.02242 9.77207 5.21311 9.77207C4.40379 9.77207 3.95174 10.0418 3.62364 10.2387C3.35387 10.4064 3.19346 10.5012 2.78516 10.5012V11.9594C3.59447 11.9594 4.04652 11.6896 4.3965 11.4928C4.66627 11.3251 4.82667 11.2303 5.22769 11.2303Z" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.78516 13.0531V14.5113C2.78516 15.3133 3.44136 15.9695 4.24338 15.9695H15.9092C16.7112 15.9695 17.3674 15.3133 17.3674 14.5113V13.0531H2.78516Z" />
                                </>
                            );
                        default:
                            return (
                                <>
                                    <path d="M4.60952 8.35449C3.60612 8.35449 2.78516 9.17546 2.78516 10.1789C2.78516 11.1823 3.60612 12.0032 4.60952 12.0032C5.61293 12.0032 6.43389 11.1823 6.43389 10.1789C6.43389 9.17546 5.61293 8.35449 4.60952 8.35449ZM15.5557 8.35449C14.5523 8.35449 13.7314 9.17546 13.7314 10.1789C13.7314 11.1823 14.5523 12.0032 15.5557 12.0032C16.5591 12.0032 17.3801 11.1823 17.3801 10.1789C17.3801 9.17546 16.5591 8.35449 15.5557 8.35449ZM10.0826 8.35449C9.07922 8.35449 8.25826 9.17546 8.25826 10.1789C8.25826 11.1823 9.07922 12.0032 10.0826 12.0032C11.086 12.0032 11.907 11.1823 11.907 10.1789C11.907 9.17546 11.086 8.35449 10.0826 8.35449Z" />
                                </>
                            );
                    }
                })()}
                
            </svg>
            // </div>
        );
    }
}