import React from 'react';

import '../../stylesheet/prusca.css';

export default class PruscaContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pruscas: [],
            previousEnabled: false,
        };
    }

    componentDidMount = () => {
        window.addEventListener('mousedown', (event) => {
            if (!this.props.enabled) return; // if the pruscas spawner is not enabled, return
            var pruscas = this.state.pruscas; // get the current pruscas array

            pruscas = pruscas.filter((prusca) => (Date.now() - prusca.date) < 1000); // removes all pruscas older than 1s
            // pushes the new prusca object into the array
            pruscas.push({
                date: Date.now(),
                x: event.x,
                y: event.y,
            });

            this.setState({ pruscas: pruscas });
        });
    }

    render() {
        return (
            <div className='prusca-container'>
                {!(this.state.previousEnabled === false && this.props.enabled === true) || [<div className='prusca' />]}

                {this.state.pruscas.map((prusca, index) => {
                    return <div key={prusca.date} className='prusca-item' style={{ top: prusca.y - 50, left: prusca.x - 50 }} />;
                })}
            </div>
        );
    }
}