import React from 'react';

export default class ContentSplash extends React.Component {
    render() {
        switch (this.props.type) {
            case 'add':
                return (
                    <svg width="79" height="79" style={{ cursor: 'pointer' }} onClick={this.props.onClick} viewBox="0 0 79 79" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M45.4211 35.38H33.5789V61H40.372C40.1283 59.8719 40 58.701 40 57.5C40 52.6515 42.0913 48.2914 45.4211 45.2725V35.38ZM62 41.9389C60.2797 41.3308 58.4285 41 56.5 41C54.2527 41 52.1104 41.4493 50.1579 42.2629V35.3333H62V41.9389ZM21.7368 19H57.2632C59.8684 19 62 21.1 62 23.6667V30.6667H17V23.6667C17 21.1 19.1316 19 21.7368 19ZM21.7368 61C19.1316 61 17 58.9 17 56.3333V35.3333H28.8421V61H21.7368Z" />
                        <path d="M58.7806 47V55.141H67V59.7203H58.7806V68H54.2194V59.7203H46V55.141H54.2194V47H58.7806Z" />
                    </svg>
                );
            case 'glitter':
                return (
                    <svg width="79" height="79" viewBox="0 0 75 75" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M57.8047 17.7336L54.885 18.885L53.1861 23.1928C52.7616 24.2691 51.2384 24.2691 50.8139 23.1928L49.115 18.885L44.8072 17.1861C44.6838 17.1374 44.5745 17.0743 44.4794 17H55.2632C56.1966 17 57.0691 17.2696 57.8047 17.7336ZM59.974 21.1778C59.9912 21.3386 60 21.5017 60 21.6667V28.6667H15V21.6667C15 19.1 17.1316 17 19.7368 17H40.0924C40.4011 18.6532 41.4835 20.1751 43.3397 20.9072L46.0313 21.9687L47.0928 24.6603C48.849 29.1132 55.151 29.1132 56.9072 24.6603L57.9687 21.9687L59.974 21.1778ZM31.5789 33.38H43.4211V59H31.5789V33.38ZM48.1579 59H55.2632C57.8684 59 60 56.9 60 54.3333V33.3333H48.1579V59ZM15 54.3333C15 56.9 17.1316 59 19.7368 59H25.8094L26.0414 58.9085C26.4211 58.7588 26.688 58.4939 26.8421 58.1822V56.8178C26.688 56.5061 26.4211 56.2412 26.0414 56.0915L20.926 54.074L18.9085 48.9586C18.4044 47.6805 16.5956 47.6805 16.0915 48.9586L15 51.7261V54.3333ZM24.0097 50.9903L26.8421 52.1074V33.3333H15V44.5827C17.6869 43.2434 21.3367 44.2128 22.6296 47.491L24.0097 50.9903Z" />
                        <path d="M16.0915 48.9586C16.5956 47.6805 18.4044 47.6805 18.9085 48.9586L20.926 54.074L26.0414 56.0915C27.3195 56.5956 27.3195 58.4044 26.0414 58.9085L20.926 60.926L18.9085 66.0414C18.4044 67.3195 16.5956 67.3195 16.0915 66.0414L14.074 60.926L8.95858 58.9085C7.68047 58.4044 7.68047 56.5956 8.95858 56.0915L14.074 54.074L16.0915 48.9586Z" />
                        <path d="M50.8139 8.80723C51.2384 7.73092 52.7616 7.73092 53.1861 8.80723L54.885 13.115L59.1928 14.8139C60.2691 15.2384 60.2691 16.7616 59.1928 17.1861L54.885 18.885L53.1861 23.1928C52.7616 24.2691 51.2384 24.2691 50.8139 23.1928L49.115 18.885L44.8072 17.1861C43.7309 16.7616 43.7309 15.2384 44.8072 14.8139L49.115 13.115L50.8139 8.80723Z" />
                    </svg>
                );
            case 'star':
                return (
                    <svg width="75" height="75" viewBox="0 0 75 75" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M31.5789 33.38H43.4211V48.0757C43.1809 48.206 42.9427 48.3555 42.7086 48.5264C41.3845 49.4922 40.6635 50.7936 40.3064 51.9662C39.9481 53.1423 39.825 54.6179 40.3768 56.1491C40.8293 57.4047 41.6053 58.3358 42.4136 59H31.5789V33.38ZM60 39.714V33.3333H48.1579V47.28H49.5678L50.6182 43.8249C51.0142 42.3555 51.9209 40.9761 53.3883 40.057C54.3849 39.4328 55.612 39 57 39C58.1162 39 59.1282 39.2798 60 39.714ZM57.9577 44.2945C58.2712 44.4908 58.4859 44.7853 58.602 45.1779C58.486 44.7853 58.2712 44.4908 57.9578 44.2945C57.9578 44.2945 57.9577 44.2945 57.9577 44.2945ZM55.2632 17H19.7368C17.1316 17 15 19.1 15 21.6667V28.6667H60V21.6667C60 19.1 57.8684 17 55.2632 17ZM15 54.3333C15 56.9 17.1316 59 19.7368 59H26.8421V33.3333H15V54.3333Z" />
                        <path d="M51.567 67.6274C50.917 68.1124 50.2611 68.1239 49.5993 67.662C48.9376 67.2001 48.7345 66.588 48.9899 65.8259L51.0795 59.0702L45.751 55.2594C45.0777 54.7975 44.8571 54.1854 45.0893 53.4232C45.3215 52.6611 45.8439 52.28 46.6565 52.28H53.2736L55.4328 45.1779C55.5257 44.7853 55.7288 44.4908 56.0423 44.2945C56.3557 44.0982 56.675 44 57 44C57.325 44 57.6443 44.0982 57.9577 44.2945C58.2712 44.4908 58.4859 44.7853 58.602 45.1779L60.7264 52.28H67.3435C68.1561 52.28 68.6785 52.6611 68.9107 53.4232C69.1429 54.1854 68.9223 54.7975 68.249 55.2594L62.9205 59.0702L65.0101 65.7912C65.2655 66.5765 65.0624 67.1943 64.4007 67.6447C63.739 68.0951 63.0947 68.0777 62.4678 67.5927L57.0348 63.5047L51.567 67.6274Z" />
                    </svg>
                );
            default:
                return <p>oops</p>
        }
    }
}