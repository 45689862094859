import React from 'react';

import '../../stylesheet/shop.css';

export default class ShopItemPreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <svg className={`shop-preview dark ${this.props.theme.className}`} viewBox="0 0 217 150" xmlns="http://www.w3.org/2000/svg">
                <rect width="217" height="150" rx="0" fill={this.props.theme.accessories['background'] ? "url(#pattern0)" : "var(--background-primary)"} />

                {/* <rect x="101.723" y="6.87598" width="14.5535" height="14.5337" fill="url(#pattern1)"/> */}
                {this.props.theme.accessories['peek'] ? (<rect x="97" y="0" width="24" height="24" fill="url(#pattern1)" />) : null}

                <path d="M135.894 130.112C141.567 130.112 146.167 125.519 146.167 119.853V102.732C146.306 100.485 148.172 98.7049 150.453 98.7049H146.167V98.7025L73.4848 98.7025V98.7049H69.198C71.481 98.7049 73.348 100.488 73.4848 102.738V119.853C73.4848 125.519 78.0842 130.112 83.7578 130.112H135.894Z" fill="var(--background-secondary)"/>
                <path d="M83.2646 19.3574C77.5909 19.3574 72.9915 23.9506 72.9915 29.6165V46.7373C72.8521 48.985 70.9863 50.7648 68.7051 50.7648H72.9915V50.7673H145.673V50.7648H149.96C147.677 50.7648 145.81 48.9822 145.673 46.7319V29.6165C145.673 23.9506 141.074 19.3574 135.4 19.3574H83.2646Z" fill="var(--background-secondary)"/>
                <rect x="59.7754" y="50.7646" width="99.1094" height="47.9355" rx="12" fill="var(--background-tertiary)"/>
                <path d="M123.194 98.7021C121.985 98.7021 120.992 99.6316 120.89 100.816V101.934C120.89 104.531 118.782 106.636 116.182 106.636H102.476C99.8755 106.636 97.7674 104.531 97.7674 101.934V100.799C97.6581 99.623 96.6689 98.7021 95.4648 98.7021H123.194Z" fill="var(--background-tertiary)"/>
                <path d="M59.7754 62.7647C59.7754 56.1372 65.148 50.7646 71.7754 50.7646H87.1961V98.7023H71.7754C65.148 98.7023 59.7754 93.3297 59.7754 86.7023V62.7647Z" fill="black" fill-opacity="0.15"/>

                <defs>
                    {this.props.theme.accessories['background'] ? (
                        <>
                            <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                <use xlinkHref="#image0_805_1564" transform="translate(0 -0.748129) scale(0.00138889 0.0019502) "/>
                            </pattern>
                            
                            <image id="image0_805_1564" width="720" height="1280" xlinkHref={this.props.theme.accessories.background.source.default} />
                        </>
                    ) : null}

                    {this.props.theme.accessories['peek'] ? (
                        <>
                            <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                                <use xlinkHref="#image1_805_1564" transform="translate(0 0.0434783) scale(0.00241546)"/>
                            </pattern>
                            
                            <image id="image1_805_1564" width="414" height="378" xlinkHref={this.props.theme.accessories.peek.source.default} />
                        </>
                    ) : null}
                </defs>
            </svg>
        );
    }
}