module.exports.getValue = (key, defaultValue, upsert = false, formatJSON = false, testFunction) => {
    var value = localStorage.getItem(key); // gets the key value from local storage

    if (value) { // if a value has already been set
        if (testFunction) { // if a test function has been provided
            
            if (testFunction(value)) return (formatJSON ? JSON.parse(value) : value); // if the test has been passed, return the value
            else { // if the test has not been passed...
                localStorage.setItem(key, (formatJSON ? JSON.stringify(defaultValue) : defaultValue)); // reset the item to the default value
                return defaultValue; // return the default value
            }
        } else return (formatJSON ? JSON.parse(value) : value); // if the value exists, parse it and return it
    } else { // if a value has NOT been set already
        if (upsert) localStorage.setItem(key, (formatJSON ? JSON.stringify(defaultValue) : defaultValue)); // if upsert is set to true, set the item to the default value
        return defaultValue; // return the default value
    }
}

module.exports.setValue = (key, value, formatJSON = false) => {
    localStorage.setItem(key, (formatJSON ? JSON.stringify(value) : value)); // sets the key value to the item
}

module.exports.keyExists = (key) => {
    var value = localStorage.getItem(key);
    
    if (value) return true;
    else return false;
}

module.exports.deleteKey = (key) => {
    localStorage.removeItem(key);
}