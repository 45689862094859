const averageGrades = (grades, failNegative, returnString = true, roundGrades = false) => {
    grades = grades.filter(grade => grade > 0); // remove zero grades
    if (roundGrades) grades = grades.map((grade) => Math.round(grade)); // rounds all grades if necessary

    let averageValue;
    if (grades.length === 0) averageValue = 0; // if there are no grades, the average is 0
    else {
        if (failNegative && grades.includes(1)) averageValue = 1; // if the grades contain a negative grade, return set the average grade to 1
        else averageValue = grades.reduce((a, b) => a + b, 0) / grades.length; // otherwise, set the average grade
    }
    
    if (returnString) return averageValue.toFixed(2).replace('.', ','); // if the returnString parameter is true, return the average grade as a string
    else return averageValue; // otherwise, return the average grade as a number
};
export default averageGrades;