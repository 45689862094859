import React from 'react';
import { SwitchTransition, CSSTransition, TransitionGroup } from "react-transition-group";
import moment from 'moment';

import averageGrades from '../../utility/averageGrades';
import truncateString from '../../utility/truncateString';
import HybridButton from '../elements/HybridButton';

import '../../stylesheet/subjectPanel.css';

export default class SubjectPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayedContent: 'grades',
            nameBeingEdited: false,

            gradeBeingEdited: null,
        };
        this.subjects = this.props.subjects;
    }

    render() {
        if (this.props.subjects[this.props.subject]) this.subjects = this.props.subjects; // simple security against undefined subjects
        return (
            <>
                <SwitchTransition>
                    <CSSTransition key={this.state.displayedContent}
                        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                        classNames='content-item'>

                        {(() => {
                            switch (this.state.displayedContent) {
                                case 'grades':
                                    return (
                                        <div className='modal-content'>
                                            <div className='modal-header'>
                                                <div style={{ position: 'relative', cursor: 'pointer' }} onClick={() => this.props.setParentState({ displayModal: false })}>
                                                    {/* back arrow */}
                                                    <svg className='modal-header-back' viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.80545 11.65L0.391265 6.80715C0.250737 6.68145 0.150154 6.55144 0.0895149 6.41713C0.0298386 6.28282 0 6.14378 0 6C0 5.85622 0.0298386 5.71718 0.0895149 5.58287C0.150154 5.44856 0.250737 5.31855 0.391265 5.19285L5.80545 0.349978C6.06533 0.116659 6.36612 0 6.70781 0C7.04855 0 7.34933 0.116659 7.61018 0.349978C7.87006 0.583297 8 0.852346 8 1.15712C8 1.4619 7.87006 1.73095 7.61018 1.96427L3.09836 6L7.61018 10.0357C7.87006 10.269 8 10.5381 8 10.8429C8 11.1477 7.87006 11.4167 7.61018 11.65C7.34933 11.8833 7.04855 12 6.70781 12C6.36612 12 6.06533 11.8833 5.80545 11.65Z" />
                                                    </svg>

                                                    {/* subject name */}
                                                    <p className='modal-title'>{this.subjects[this.props.subject].name || `Predmet #${this.props.subject + 1}`}</p>

                                                    {/* subject average grade */}
                                                    <p className='modal-tooltip'>({averageGrades(this.subjects[this.props.subject].grades.map((grade) => grade.value))})</p>
                                                </div>

                                                {/* subject settings button */}
                                                <div className='modal-button' onClick={() => { this.setState({ displayedContent: 'settings', gradeBeingEdited: null }); }}>
                                                    {/* cog icon */}
                                                    <svg width="18" height="18" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M14.1707 8C14.1707 7.816 14.1625 7.64 14.1461 7.456L15.6764 6.328C16.0055 6.088 16.096 5.64 15.8903 5.288L14.3518 2.704C14.1461 2.352 13.7018 2.208 13.3233 2.368L11.5543 3.096C11.2499 2.888 10.929 2.704 10.5917 2.552L10.3531 0.704C10.3037 0.304 9.94996 0 9.53857 0H6.46966C6.05005 0 5.69626 0.304 5.64689 0.704L5.40829 2.552C5.07095 2.704 4.75007 2.888 4.44565 3.096L2.6767 2.368C2.29823 2.208 1.85394 2.352 1.64825 2.704L0.109673 5.296C-0.0960185 5.648 -0.00551423 6.088 0.323592 6.336L1.85394 7.464C1.83748 7.64 1.82925 7.816 1.82925 8C1.82925 8.184 1.83748 8.36 1.85394 8.544L0.323592 9.672C-0.00551423 9.912 -0.0960185 10.36 0.109673 10.712L1.64825 13.296C1.85394 13.648 2.29823 13.792 2.6767 13.632L4.44565 12.904C4.75007 13.112 5.07095 13.296 5.40829 13.448L5.64689 15.296C5.69626 15.696 6.05004 16 6.46143 16H9.53034C9.94173 16 10.2955 15.696 10.3449 15.296L10.5835 13.448C10.9208 13.296 11.2417 13.112 11.5461 12.904L13.3151 13.632C13.6935 13.792 14.1378 13.648 14.3435 13.296L15.8821 10.712C16.0878 10.36 15.9973 9.92 15.6682 9.672L14.1378 8.544C14.1625 8.36 14.1707 8.184 14.1707 8ZM8.03291 10.8C6.44497 10.8 5.15323 9.544 5.15323 8C5.15323 6.456 6.44497 5.2 8.03291 5.2C9.62085 5.2 10.9126 6.456 10.9126 8C10.9126 9.544 9.62085 10.8 8.03291 10.8Z" />
                                                    </svg>
                                                </div>
                                            </div>

                                            <div className='subject-panel'>
                                                <TransitionGroup className="subject-grades">
                                                    {this.subjects[this.props.subject].grades.map((grade, index) => {
                                                        return (
                                                            <CSSTransition timeout={500} classNames="root-transition-item">
                                                                <div className='subject-grade'>
                                                                    <p className='subject-grade-value' style={{ textDecoration: ((this.state.gradeBeingEdited !== null && this.state.gradeBeingEdited === index) ? 'underline': null) }}>{grade.value}</p>
                                                                    <p className='subject-grade-date' style={{ textDecoration: ((this.state.gradeBeingEdited !== null && this.state.gradeBeingEdited === index) ? 'underline': null) }}>{moment(grade.addedAt).format('DD/MM/YYYY')}</p>

                                                                    <div className='subject-grade-buttons'>
                                                                        <div style={{ backgroundColor: (['dark', 'light'].includes(this.props.userTheme) ? 'var(--indicator-color)' : null) }} onClick={() => this.setState({ gradeBeingEdited: ((this.state.gradeBeingEdited !== null && this.state.gradeBeingEdited === index) ? null : index) })}>
                                                                            <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" style={{ transform: ((this.state.gradeBeingEdited !== null && this.state.gradeBeingEdited === index) ? 'rotateZ(-45deg)' : null) }}>
                                                                                <path d="M8.98773 3.49103L6.51234 0.997927L7.09034 0.404732C7.35082 0.144252 7.65801 0.00948411 8.01192 0.000427702C8.36625 -0.00862871 8.69152 0.126139 8.98773 0.404732L9.60642 1.01151C9.89359 1.28062 10.0236 1.59953 9.99649 1.96826C9.9698 2.33655 9.83095 2.64641 9.57994 2.89783L8.98773 3.49103ZM8.20695 4.27247L2.48895 10H0V7.52048L5.718 1.79295L8.20695 4.27247Z" />
                                                                            </svg>
                                                                        </div>
                                                                        <div style={{ backgroundColor: (['dark', 'light'].includes(this.props.userTheme) ? 'var(--destructive-color)' : null) }} onClick={() => { this.props.removeGrade(this.subjects[this.props.subject].identifier, index); this.setState({ gradeBeingEdited: null }); }}>
                                                                            <svg viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M10.2049 0.302651C9.81151 -0.0907709 9.1754 -0.0907709 8.78198 0.302651L5.25018 3.82723L1.71802 0.295066C1.3246 -0.0983554 0.688488 -0.0983554 0.295066 0.295066C-0.0983554 0.688488 -0.0983554 1.3246 0.295066 1.71802L3.82705 5.25L0.295066 8.78198C-0.0983554 9.1754 -0.0983554 9.81151 0.295066 10.2049C0.688488 10.5984 1.3246 10.5984 1.71802 10.2049L5.25 6.67295L8.78198 10.2049C9.1754 10.5984 9.81151 10.5984 10.2049 10.2049C10.5984 9.81151 10.5984 9.1754 10.2049 8.78198L6.67295 5.25L10.2049 1.71802C10.5908 1.33218 10.5908 0.688488 10.2049 0.302651Z" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </CSSTransition>
                                                        );
                                                    })}
                                                </TransitionGroup>

                                                <div className='subject-input'>
                                                    {[1, 2, 3, 4, 5].map((grade) => <div style={{ backgroundColor: ((this.state.gradeBeingEdited !== null && ['dark', 'light'].includes(this.props.userTheme)) ? 'var(--indicator-color)' : null), textDecoration: (this.state.gradeBeingEdited !== null ? 'underline' : null) }}
                                                        onClick={() => {
                                                            if (this.state.gradeBeingEdited !== null) {
                                                                this.props.editGrade(this.subjects[this.props.subject].identifier, this.state.gradeBeingEdited, grade);
                                                                this.setState({ gradeBeingEdited: null });
                                                            } else this.props.addGrade(this.subjects[this.props.subject].identifier, grade);
                                                        }}>{grade}</div>)}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                case 'settings':
                                    return (
                                        <div className='modal-content'>
                                            <div className='modal-header'>
                                                <div style={{ position: 'relative', cursor: 'pointer' }} onClick={() => this.setState({ displayedContent: 'grades' })}>
                                                    {/* back arrow */}
                                                    <svg className='modal-header-back' viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.80545 11.65L0.391265 6.80715C0.250737 6.68145 0.150154 6.55144 0.0895149 6.41713C0.0298386 6.28282 0 6.14378 0 6C0 5.85622 0.0298386 5.71718 0.0895149 5.58287C0.150154 5.44856 0.250737 5.31855 0.391265 5.19285L5.80545 0.349978C6.06533 0.116659 6.36612 0 6.70781 0C7.04855 0 7.34933 0.116659 7.61018 0.349978C7.87006 0.583297 8 0.852346 8 1.15712C8 1.4619 7.87006 1.73095 7.61018 1.96427L3.09836 6L7.61018 10.0357C7.87006 10.269 8 10.5381 8 10.8429C8 11.1477 7.87006 11.4167 7.61018 11.65C7.34933 11.8833 7.04855 12 6.70781 12C6.36612 12 6.06533 11.8833 5.80545 11.65Z" />
                                                    </svg>

                                                    {/* subject name */}
                                                    <p className='modal-title'>{this.subjects[this.props.subject].name || `Predmet #${this.props.subject + 1}`}</p>
                                                </div>
                                            </div>

                                            <div className='hybrid-button-container'>

                                                {/* edit name */}
                                                <HybridButton displayOutline={this.state.nameBeingEdited} outlineColor='var(--indicator-color)'
                                                    onClick={() => {
                                                        if (this.state.nameBeingEdited) this.setState({ nameBeingEdited: false });
                                                        else this.setState({ nameBeingEdited: true });
                                                    }}
                                                    
                                                    hybrid={true} displayInput={this.state.nameBeingEdited}
                                                    inputPlaceholder='Naziv' inputDefault={this.subjects[this.props.subject].name || ''}
                                                    
                                                    onChange={(event) => {
                                                        let newValue = event.target.value;
                                                            newValue = newValue.slice(0, 25); // limit to 25 characters

                                                        if (event.target.value.endsWith(' ')) newValue = newValue.trim() + ' '; // adds a trailing space if the user presses a space after the last character
                                                        else newValue = newValue.trim(); // else just trims without the trailing space

                                                        event.target.value = newValue;
                                                        this.props.editSubjectName(this.subjects[this.props.subject].identifier, newValue); // update the subject name
                                                    }}
                                                    onKeyDown={(event) => {
                                                        if (event.key === 'Enter') this.setState({ nameBeingEdited: false });
                                                    }}

                                                    iconType='edit' iconFill='var(--indicator-color)' rotateIcon={this.state.nameBeingEdited}
                                                    buttonText='Promijeni naziv' buttonTooltip={truncateString(this.subjects[this.props.subject].name || '', 10)} />

                                                {/* clear grades */}
                                                <HybridButton onClick={() => { this.props.clearSubject(this.subjects[this.props.subject].identifier); this.setState({ displayedContent: 'grades' }); }}
                                                    buttonText='Očisti' buttonTooltip='(ocjene)'
                                                    iconType='clear' iconFill="var(--destructive-color)" buttonSize='small' />

                                                {/* delete subject */}
                                                <HybridButton onClick={() => { this.props.removeSubject(this.subjects[this.props.subject].identifier); this.props.setParentState({ displayModal: false }); }}
                                                    buttonText='Izbriši'
                                                    iconType='delete' iconFill="var(--destructive-color)" buttonSize='small' />
                                            </div>
                                        </div>
                                    );
                                default:
                                    return <p>oops</p>;
                            }
                        })()}
                    </CSSTransition>
                </SwitchTransition>
            </>
        );
    }
}