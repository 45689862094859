import Axios from 'axios';
import { Device } from '@capacitor/device';
import LocalStorage from '../base/LocalStorage';
import Defaults from './Defaults';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    redeemCode: (code) => {
        return new Promise(async (resolve, reject) => {
            Axios.post(`https://api.raspo.red/redeem/${code}`, { device: await Device.getInfo() }).then((response) => resolve(response.data)).catch((error) => reject(error.response));
        });
    },
    validateCode: (code) => {
        return new Promise((resolve, reject) => {
            Axios.get(`https://api.raspo.red/validate/${code}`).then((response) => resolve(response.data)).catch((error) => reject(error.response));
        });
    },

    getCodes: () => {
        return new Promise((resolve, reject) => {
            Axios.get(`https://api.raspo.red/codes`, { headers: { 'Authorization': '62d18dcab131238ecd8699ae' } }).then((response) => resolve(response.data)).catch((error) => reject(error.response));
        });
    },
    generateCode: (themes) => {
        return new Promise((resolve, reject) => {
            Axios.post(`https://api.raspo.red/codes/generate`, { themes }, { headers: { 'Authorization': '62d18dcab131238ecd8699ae' } }).then((response) => resolve(response.data)).catch((error) => reject(error.response));
        });
    },

    getAnalytics: (id) => {
        return new Promise((resolve, reject) => {
            Axios.post(`https://api.raspo.red/analytics`, { id }, { headers: { 'Authorization': '62d18dcab131238ecd8699ae' } }).then((response) => resolve(response.data)).catch((error) => reject(error.response));
        });
    },
    recordAnalytic: (id, type) => {
        return new Promise((resolve, reject) => {
            Axios.post(`https://api.raspo.red/analytics/record`, { id, type, data: {} }, { headers: { 'Authorization': '62d18dcab131238ecd8699ae' } })
                .then((response) => resolve(response.data))
                .catch(() => {
                    var offlineAnalytics = LocalStorage.getValue('offlineAnalytics', Defaults.offlineAnalytics, true, true);
                    if (!offlineAnalytics[type]) offlineAnalytics[type] = [];
        
                    // if the request does not go through, save the analytic into local storage to record later
                    offlineAnalytics[type].push({ createdAt: new Date() });
                    LocalStorage.setValue('offlineAnalytics', offlineAnalytics, true);
                });
        });
    },
    recordOfflineAnalytics: (id, type) => {
        var offlineAnalytics = LocalStorage.getValue('offlineAnalytics', Defaults.offlineAnalytics, true, true);

        // if there are analytic records saved for the provided type, record them in bulk
        if (offlineAnalytics[type] && offlineAnalytics[type].length > 0) {
            new Promise((resolve, reject) => {
                Axios.post(`https://api.raspo.red/analytics/record`, { id, type: type === 'appOpened' ? 'offlineAppOpened' : type, data: offlineAnalytics[type] }, { headers: { 'Authorization': '62d18dcab131238ecd8699ae' } }).then((response) => {
                    resolve(response.data);

                    // if the request goes through, clear the offline analytic records for the provided type
                    offlineAnalytics[type] = [];
                    LocalStorage.setValue('offlineAnalytics', offlineAnalytics, true);
                }).catch((error) => reject(error.response));
            });
        }
    },

    getVersion: () => {
        return new Promise((resolve, reject) => {
            Axios.get(`https://api.raspo.red/version`).then((response) => resolve(response.data)).catch((error) => reject(error.response));
        });
    },

    getCollections: () => {
        return new Promise((resolve, reject) => {
            Axios.get(`https://api.raspo.red/collections`, { headers: { 'Authorization': '62d18dcab131238ecd8699ae' } }).then((response) => resolve(response.data)).catch((error) => reject(error.response));
        });
    },
    getCollectionVersions: () => {
        return new Promise((resolve, reject) => {
            Axios.get(`https://api.raspo.red/collections/versions`, { headers: { 'Authorization': '62d18dcab131238ecd8699ae' } }).then((response) => resolve(response.data)).catch((error) => reject(error.response));
        });
    },
    getTableShareData: (id) => {
        return new Promise((resolve, reject) => {
            Axios.get(`https://api.raspo.red/share/${id}`, { headers: { 'Authorization': '62d18dcab131238ecd8699ae' } }).then((response) => resolve(response.data)).catch((error) => reject(error.response));
        });
    },
    createTableShareCode: (data) => {
        return new Promise((resolve, reject) => {
            Axios.post(`https://api.raspo.red/share/create`, { data }, { headers: { 'Authorization': '62d18dcab131238ecd8699ae' } }).then((response) => resolve(response.data)).catch((error) => reject(error.response));
        });
    },
}
