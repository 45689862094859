var moment = require('moment')

var timeGenerator = () => {
    var timeArray = Array(104).fill(104);
    var lastTime = null;

    return timeArray.map(() => {
        var date = moment(lastTime || '06:00', 'HH:mm').local(true);
        date.add(5, 'minutes');

        lastTime = date.format('HH:mm');
        return date.format('HH:mm');
    });
}

module.exports = {
    default: {
        'school': {
            startsAt: '07:25',
            shortBreakLength: 5,
            longBreakLength: 15,
            longBreakAfter: 3,
            classLength: 45,
            classAmount: 8
        },
        'custom': {
            startsAt: '07:25',
        },
    },
    list: [
        {
            'name': 'Početak smjene:',
            'value': 'startsAt',
            'values': timeGenerator().map((time) => { return { value: time } })
        },
        {
            'name': 'Dužina časa:',
            'value': 'classLength',
            'values': [
                { value: 15, name: '15 minuta' },
                { value: 20, name: '20 minuta' },
                { value: 25, name: '25 minuta' },
                { value: 30, name: '30 minuta' },
                { value: 35, name: '35 minuta' },
                { value: 40, name: '40 minuta' },
                { value: 45, name: '45 minuta' },
                { value: 50, name: '50 minuta' },
                { value: 55, name: '55 minuta' },
                { value: 60, name: '60 minuta' },
            ]
        },
        {
            'name': 'Dužina odmora:',
            'value': 'shortBreakLength',
            'values': [
                { value: 5, name: '5 minuta' },
                { value: 10, name: '10 minuta' },
                { value: 15, name: '10 minuta' },
            ]
        },
        {
            'name': 'Dužina velikog odmora:',
            'value': 'longBreakLength',
            'values': [
                { value: 15, name: '15 minuta' },
                { value: 20, name: '20 minuta' },
                { value: 25, name: '25 minuta' },
                { value: 30, name: '30 minuta' },
            ]
        },
        {
            'name': 'Veliki odmor poslije:',
            'value': 'longBreakAfter',
            'values': [
                { value: 1 },
                { value: 2 },
                { value: 3 },
                { value: 4 },
                { value: 5 },
                { value: 6 },
                { value: 7 },
                { value: 8 },
                { value: 9 },
                { value: 10 },
            ]
        },
        {
            'name': 'Broj časova:',
            'value': 'classAmount',
            'values': [
                { value: 1 },
                { value: 2 },
                { value: 3 },
                { value: 4 },
                { value: 5 },
                { value: 6 },
                { value: 7 },
                { value: 8 },
                { value: 9 },
                { value: 10 },
            ]
        },
    ],
}