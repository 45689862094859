import React from 'react';
import moment from 'moment';

import PeriodPill from '../elements/PeriodPill';

import SUBJECTS from '../../backend/configuration/Subjects';
import capitalizeString from '../../utility/capitalizeString';

import '../../stylesheet/timetable.css';

export default class TimetableContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedSubject: null,
            periodBeingEdited: null,
            tableState: 'default',
        };

        this.watingClick = null; // a reference to timeout function
        this.lastClick = 0; // a watchdog for difference between 2 clicks
    }

    periodClickHandler = (event, data) => {
        if (this.lastClick && event.timeStamp - this.lastClick < 250 && this.watingClick) {
            this.lastClick = 0; // reset watchdog
            clearTimeout(this.watingClick); // clear timeout 

            /* double click code */
            // this.setState({ selectedSubject: null, periodBeingEdited: (this.state.periodBeingEdited ? null : data) });

            this.watingClick = null; // reset reference to timeout function
        } else {
            this.lastClick = event.timeStamp; // set watchdog
            this.watingClick = setTimeout(() => { // the timeout for single click execution
                this.watingClick = null;

                /* single click code */
                if (this.state.periodBeingEdited) return;
                this.setState({ selectedSubject: (!(this.state.selectedSubject && this.state.selectedSubject === data.period.periodTitle) ? data.period.periodTitle : null) });
            }, 251);
        }
    }

    render() {
        if (!this.props.table) return null;
        if (!this.props.userTable.startsWith('custom') && this.state.tableState === 'edit') this.setState({ tableState: 'default' });

        var maxLength = this.props.table.table.map((day) => day.length).sort((a, b) => {
		  if (a < b) return -1;
		  if (a > b) return 1;
		  return 0;
		}).reverse()[0];
        var maxLengthDay = this.props.table.table.map((day) => day.length).indexOf(maxLength);

        var currentPeriod = {
            day: (moment(this.props.date).day() !== 0 && moment(this.props.date).day() !== 6) ? moment(this.props.date).day() - 1 : 0, // make sure the current day is never a weekend day
            period: this.props.table.getCurrentPeriod(this.props.date)?.index / 2,
        }

        let perpx = 2.7/1080;
        let scaleRatio = perpx * window.screen.width;
        return (
            <div id='timetable-target' style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }} onClick={(event) => event.target.id !== 'timetable-target' || this.setState({ selectedSubject: null })}>
                
                {(this.props.table?.getCurrentPeriod(this.props.date) && !this.props.keyboardShown) ? (
                    <PeriodPill currentPeriod={this.props.table?.getCurrentPeriod(this.props.date)} date={this.props.date} />
                ) : null}

                <div className={`timetable-container ${(this.props.blurContent) ? 'blurred' : ''}`} style={{ transform: `scale(${(scaleRatio > 1.5) ? 1.5 : scaleRatio})` }}>
                    <div className='timetable-bar'>
                        {!this.props.userTable.startsWith('custom') || (
                            <>
                                <div className='timetable-bar-button' onClick={() => { this.props.setParentState({ displayModal: true, modalContent: 'settings' })}}>
                                    <svg style={{ fill: 'var(--text-secondary)' }} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.1707 8C14.1707 7.816 14.1625 7.64 14.1461 7.456L15.6764 6.328C16.0055 6.088 16.096 5.64 15.8903 5.288L14.3518 2.704C14.1461 2.352 13.7018 2.208 13.3233 2.368L11.5543 3.096C11.2499 2.888 10.929 2.704 10.5917 2.552L10.3531 0.704C10.3037 0.304 9.94996 0 9.53857 0H6.46966C6.05005 0 5.69626 0.304 5.64689 0.704L5.40829 2.552C5.07095 2.704 4.75007 2.888 4.44565 3.096L2.6767 2.368C2.29823 2.208 1.85394 2.352 1.64825 2.704L0.109673 5.296C-0.0960185 5.648 -0.00551423 6.088 0.323592 6.336L1.85394 7.464C1.83748 7.64 1.82925 7.816 1.82925 8C1.82925 8.184 1.83748 8.36 1.85394 8.544L0.323592 9.672C-0.00551423 9.912 -0.0960185 10.36 0.109673 10.712L1.64825 13.296C1.85394 13.648 2.29823 13.792 2.6767 13.632L4.44565 12.904C4.75007 13.112 5.07095 13.296 5.40829 13.448L5.64689 15.296C5.69626 15.696 6.05004 16 6.46143 16H9.53034C9.94173 16 10.2955 15.696 10.3449 15.296L10.5835 13.448C10.9208 13.296 11.2417 13.112 11.5461 12.904L13.3151 13.632C13.6935 13.792 14.1378 13.648 14.3435 13.296L15.8821 10.712C16.0878 10.36 15.9973 9.92 15.6682 9.672L14.1378 8.544C14.1625 8.36 14.1707 8.184 14.1707 8ZM8.03291 10.8C6.44497 10.8 5.15323 9.544 5.15323 8C5.15323 6.456 6.44497 5.2 8.03291 5.2C9.62085 5.2 10.9126 6.456 10.9126 8C10.9126 9.544 9.62085 10.8 8.03291 10.8Z" />
                                    </svg>
                                </div>

                                <div className='timetable-bar-button' style={{ maxWidth: ((this.state.tableState !== 'edit') ? 'calc(100%)' : '27px') }} onClick={() => { this.setState({ selectedSubject: null, tableState: (this.state.tableState === 'edit') ? 'default' : 'edit' }); this.props.addVisited('tableEdit'); }}>
                                    {this.props.visited['tableEdit'] > 5 || <p style={{ color: (this.state.tableState === 'edit') ? 'var(--indicator-color)' : 'var(--text-secondary)' }}>Uredi raspored</p>}

                                    <svg style={(this.state.tableState === 'edit') ? { transform: 'rotateZ(-45deg)', fill: 'var(--indicator-color)' } : { fill: 'var(--text-secondary)' }} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.7548 6.1093L11.4156 1.74637L12.4288 0.708281C12.8854 0.252441 13.4239 0.0165972 14.0442 0.000748478C14.6653 -0.0151002 15.2355 0.220744 15.7548 0.708281L16.8393 1.77014C17.3427 2.24108 17.5706 2.79918 17.523 3.44445C17.4762 4.08896 17.2328 4.63122 16.7929 5.07121L15.7548 6.1093ZM14.3861 7.47682L4.36293 17.5H0V13.1608L10.0232 3.13766L14.3861 7.47682Z" />
                                    </svg>
                                </div>
                            </>
                        )}
                    </div>

                    <div className='timetable-column'>
                        <div className='timetable-cell timetable-time' key={0}></div>

                        {!this.props.table.table.some((day) => day.length > 0) || (
                            this.props.table.table[currentPeriod.day].concat((this.props.table.table[maxLengthDay].length !== this.props.table.table[currentPeriod.day].length && this.props.table.userTable.type === 'custom') ? Array((maxLength) - this.props.table.table[currentPeriod.day].length).fill(null).map((_, blankPeriodIndex) => this.props.table.table[maxLengthDay][(maxLength - 1) - blankPeriodIndex]).reverse() : []).filter((period) => period.type === 'CLASS')
                                .map((period) => [period.startsAt.format('HH:mm'), period.endsAt.format('HH:mm')]).map((times, timeIndex) => {
                                    return (
                                        <div className='timetable-cell timetable-time' key={timeIndex} style={{ opacity: (this.state.selectedSubject ? (this.props.table.table.some((day) => day.filter((period) => period.type === 'CLASS')[timeIndex]?.periodTitle === this.state.selectedSubject) ? 1 : .5) : 1) }}>
                                        
                                            {(this.state.selectedSubject ? (this.props.table.table.some((day) => day.filter((period) => period.type === 'CLASS')[timeIndex]?.periodTitle === this.state.selectedSubject) ? (
                                                <>
                                                    {this.props.table.table.find((day) => day.filter((period) => period.type === 'CLASS')[timeIndex]?.periodTitle === this.state.selectedSubject).filter((period) => period.type === 'CLASS')[timeIndex].startsAt.format('HH:mm')}<br />
                                                    {this.props.table.table.find((day) => day.filter((period) => period.type === 'CLASS')[timeIndex]?.periodTitle === this.state.selectedSubject).filter((period) => period.type === 'CLASS')[timeIndex].endsAt.format('HH:mm')}
                                                </>
                                            ) : (
                                                <>
                                                    {times[0]}<br />
                                                    {times[1]}
                                                </>
                                            )) : (
                                                <>
                                                    {times[0]}<br />
                                                    {times[1]}
                                                </>
                                            ))}
                                        </div>
                                    );
                            })
                        )}
                    </div>

                    {this.props.table.table.map((day, dayIndex) => {
                        return (
                            <div className='timetable-column' key={dayIndex}>
                                {/* <div className='timetable-cell timetable-day' style={{ opacity: (this.state.selectedPeriod ? ((this.state.selectedPeriod[0] === dayIndex) ? 1 : .5) : 1) }}> */}
                                <div className='timetable-cell timetable-day' key={0} style={{ opacity: (this.state.selectedSubject ? (this.props.table.table[dayIndex].some((period) => period.periodTitle === this.state.selectedSubject) ? 1 : .5) : 1), boxShadow: ((currentPeriod.day === dayIndex) ? '0px 0px 0px 2px var(--text-primary) inset' : '0px 0px 0px 2px var(--background-secondary) inset') }}>
                                    {capitalizeString(moment().day(dayIndex + 1).format('dddd').slice(0, 3))}
                                </div>

                                {(day.length === 0) || (
                                    day.filter((period) => period.type === 'CLASS').map((period, periodIndex) => {
                                        var periodTitle = SUBJECTS[period.periodTitle]?.value || (period.periodTitle || '');
                                        if (periodTitle.length > 14) periodTitle = periodTitle.slice(0, 14) + '...';

                                        return ((this.state.tableState === 'edit') ? (
                                            <input className='timetable-cell timetable-period timetable-cell-input' key={(this.props.table.userTable.type === 'custom' ? period.index : periodIndex) + 1} type="text" maxLength={25} defaultValue={SUBJECTS[period.periodTitle]?.value || period.periodTitle}
                                                onChange={(event) => {
                                                    let newValue = event.target.value;

                                                    if (event.target.value.endsWith(' ')) newValue = newValue.trim() + ' '; // adds a trailing space if the user presses a space after the last character
                                                    else newValue = newValue.trim(); // else just trims without the trailing space

                                                    event.target.value = newValue;
                                                    this.props.updateCustomTablePeriod(this.props.userTable.split('/')[1], dayIndex, (this.props.table.userTable.type === 'custom' ? period.index : periodIndex), newValue);
                                                }} style={(this.state.tableState === 'edit') ? { boxShadow: '0px 0px 0px 2px var(--indicator-color) inset' } : null}></input>
                                        ) : (
                                            <div className='timetable-cell timetable-period' key={(this.props.table.userTable.type === 'custom' ? period.index : periodIndex) + 1} onClick={(event) => this.periodClickHandler(event, { period, dayIndex, index: (this.props.table.userTable.type === 'custom' ? period.index : periodIndex) })}
                                                style={{ opacity: (this.state.selectedSubject ? ((this.state.selectedSubject === period.periodTitle) ? 1 : .5) : 1), boxShadow: ((currentPeriod.day === dayIndex && currentPeriod.period === (this.props.table.userTable.type === 'custom' ? period.index : periodIndex) && periodTitle) ? '0px 0px 0px 2px var(--text-primary) inset' : '0px 0px 0px 2px var(--background-secondary) inset') }}>
                                                
                                                <span>{periodTitle}</span>
                                            </div>
                                        ));
                                    })
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}