import React from 'react';
import { SpinnerCircularFixed } from 'spinners-react';

import '../../stylesheet/elements/hybridButton.css';

// react function component template
export default function ButtonIcons(props) {
    switch (props.type) {
        case 'edit':
            return (
                <svg className='hybrid-button-icon' style={props.rotateIcon ? { transform: 'rotateZ(-45deg)' } : null} viewBox="0 0 22 22" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8033 9.18923L12.8278 6.19751L13.5226 5.48568C13.8357 5.1731 14.2049 5.01138 14.6303 5.00051C15.0562 4.98965 15.4472 5.15137 15.8033 5.48568L16.5469 6.21381C16.8921 6.53674 17.0484 6.91944 17.0158 7.36191C16.9837 7.80386 16.8168 8.17569 16.5151 8.4774L15.8033 9.18923ZM14.8648 10.127L7.99172 17H5V14.0246L11.873 7.15154L14.8648 10.127Z" />
                </svg>
            );
        case 'clear':
            return (
                <svg className='hybrid-button-icon' style={props.rotateIcon ? { transform: 'rotateZ(-45deg)' } : null} viewBox="0 0 22 22" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.7338 5.27531C16.3788 4.92036 15.8055 4.92036 15.4505 5.27531L11 9.71672L6.54949 5.26621C6.19454 4.91126 5.62116 4.91126 5.26621 5.26621C4.91126 5.62116 4.91126 6.19454 5.26621 6.54949L9.71672 11L5.26621 15.4505C4.91126 15.8055 4.91126 16.3788 5.26621 16.7338C5.62116 17.0887 6.19454 17.0887 6.54949 16.7338L11 12.2833L15.4505 16.7338C15.8055 17.0887 16.3788 17.0887 16.7338 16.7338C17.0887 16.3788 17.0887 15.8055 16.7338 15.4505L12.2833 11L16.7338 6.54949C17.0796 6.20364 17.0796 5.62116 16.7338 5.27531V5.27531Z" />
                </svg>
            );
        case 'delete':
            return (
                <svg className='hybrid-button-icon' style={props.rotateIcon ? { transform: 'rotateZ(-45deg)' } : null} viewBox="0 0 22 22" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.68571 15.6667C6.68571 16.4 7.30286 17 8.05714 17H13.5429C14.2971 17 14.9143 16.4 14.9143 15.6667V9C14.9143 8.26667 14.2971 7.66667 13.5429 7.66667H8.05714C7.30286 7.66667 6.68571 8.26667 6.68571 9V15.6667ZM14.9143 5.66667H13.2L12.7131 5.19333C12.5897 5.07333 12.4114 5 12.2331 5H9.36686C9.18857 5 9.01029 5.07333 8.88686 5.19333L8.4 5.66667H6.68571C6.30857 5.66667 6 5.96667 6 6.33333C6 6.7 6.30857 7 6.68571 7H14.9143C15.2914 7 15.6 6.7 15.6 6.33333C15.6 5.96667 15.2914 5.66667 14.9143 5.66667Z" />    
                </svg>
            );
        case 'success':
            return (
                <svg className='hybrid-button-icon' style={props.rotateIcon ? { transform: 'rotateZ(-45deg)', width: '24px', marginRight: '0px' } : { width: '24px', marginRight: '0px' }} viewBox="0 0 17 12" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.1562 0L6.53846 8.23346L1.84385 3.76066L0 5.52229L6.53846 11.7692L17 1.77413L15.1562 0Z" />
                </svg>
            );
        case 'loading':
            return (
                <SpinnerCircularFixed size='25px' thickness={200} color={props.fill} secondaryColor="var(--background-secondary)" />
            );
        case 'copy':
            return (
                <svg className='hybrid-button-icon' style={props.rotateIcon ? { transform: 'rotateZ(-45deg)' } : null} viewBox="0 0 22 22" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.7971 14.5217C9.43961 14.5217 9.13043 14.3913 8.86957 14.1304C8.6087 13.8696 8.47826 13.5604 8.47826 13.2029V6.31884C8.47826 5.96135 8.6087 5.65217 8.86957 5.3913C9.13043 5.13043 9.43961 5 9.7971 5H14.942C15.2995 5 15.6087 5.13043 15.8696 5.3913C16.1304 5.65217 16.2609 5.96135 16.2609 6.31884V13.2029C16.2609 13.5604 16.1304 13.8696 15.8696 14.1304C15.6087 14.3913 15.2995 14.5217 14.942 14.5217H9.7971ZM7.31884 17C6.96135 17 6.65217 16.8696 6.3913 16.6087C6.13043 16.3478 6 16.0386 6 15.6812V8.26087C6 8.07729 6.06531 7.92271 6.19594 7.7971C6.32618 7.6715 6.48309 7.6087 6.66667 7.6087C6.85024 7.6087 7.00483 7.6715 7.13043 7.7971C7.25604 7.92271 7.31884 8.07729 7.31884 8.26087V15.6812H13C13.1836 15.6812 13.3382 15.744 13.4638 15.8696C13.5894 15.9952 13.6522 16.1498 13.6522 16.3333C13.6522 16.5169 13.5894 16.674 13.4638 16.8046C13.3382 16.9349 13.1836 17 13 17H7.31884Z" />
                </svg>
            );
        default:
            return <p>oops</p>;
    }
}