import React from 'react';
import { App } from '@capacitor/app';
import Swipe from 'react-easy-swipe';

import '../../stylesheet/modal.css';
import '../../stylesheet/categories.css';
import '../../stylesheet/elements/contentSplash.css';

export default class Modal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOffset: 0,
            modalTransition: '0s',
        };

        this.modalRef = React.createRef();
    }

    onSwipeStart = () => { this.setState({ modalOffset: 0, modalTransition: '0s' }); } // reset the modal offset and set the transition duration 0 seconds
    onSwipeEnd = () => { this.setState({ modalOffset: 0, modalTransition: '.25s' }); } // reset the modal offset and set the transition duration .25 seconds

    onSwipeMove = (position) => { // set the modal offset to the swipe position
        if (this.props.dragLocked) return this.setState({ modalOffset: 0 });

        if (this.modalRef.current?.offsetHeight === (document.body.offsetHeight - 80)) return; // if the modal takes up the whole page, don't move it
        this.setState({ modalOffset: (position.y >= 0 ? position.y : 0) });
    }
    onSwipeDown = () => {
        if (this.props.dragLocked) return this.setState({ modalOffset: 0 });

        if (this.modalRef.current?.offsetHeight === (document.body.offsetHeight - 80)) return; // if the modal takes up the whole page, don't move it
        this.props.setParentState({ modalOffset: 0, modalTransition: '.25s', displayModal: false });
    }

    componentDidMount() {
        App.addListener('backButton', () => this.props.setParentState({ displayModal: false })); // close the modal once the back button was triggered
    }
    componentWillUnmount() {
        App.removeAllListeners(); // removes all listeners
    }

    render() {
        return (
            <div className='overlay' onClick={(event) => {
                if (event.target.className === 'modal-swipe-wrapper') this.props.setParentState({ displayModal: false });
            }}>

                {/* swipe component */}
                <Swipe onSwipeStart={this.onSwipeStart}
                    onSwipeEnd={this.onSwipeEnd}
                    onSwipeMove={this.onSwipeMove}
                    onSwipeDown={this.onSwipeDown}
                    tolerance={150}
                    className='modal-swipe-wrapper'> {/* the transition class name */}

                    {/* modal element */}
                    <div id='modal' className='modal' style={{
                        position: 'relative',
                        transform: `translateY(${this.state.modalOffset}px)`,
                        transition: `transform ${this.state.modalTransition} ease-in-out 0s, background-color .25s ease-in-out`,
                        overflowY: (this.modalRef.current?.offsetHeight === (document.body.offsetHeight - 80) ? 'auto' : 'visible'),
                    }} ref={this.modalRef}>
                        
                        <div className='modal-indicator'></div>
                        {this.props.children}
                    </div>
                </Swipe>
            </div>
        );
    }
}