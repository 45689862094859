import React from 'react';
import { SwitchTransition, CSSTransition } from "react-transition-group";

import ShopContainer from '../containers/ShopContainer';
import ThemeItem from '../items/ThemeItem';

import sortThemesByOwnership from '../../utility/sortThemesByOwnership';

import '../../stylesheet/theme.css';

export default class PeriodContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayedContent: null,
            previewedTheme: null,
            returnToList: false,
        };
    }

    render() {
        return (
            <>
                <SwitchTransition>
                    <CSSTransition key={this.state.displayedContent}
                        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                        classNames='content-item'>

                        {(() => {
                            switch(this.state.displayedContent) {
                                default:
                                    return (
                                        <div className='modal-content'>
                                            <div className='modal-header'>
                                                <div style={{ position: 'relative' }}>
                                                    {/* title */}
                                                    <p className='modal-title'>Boje</p>
                                                </div>
                                                
                                                {/* shop button */}
                                                <div className='modal-button' onClick={() => { this.setState({ displayedContent: 'shop' }); this.props.addVisited('shop'); }}>
                                                    {/* indicator element */}
                                                    {this.props.visited['shop'] > 0 || (<div className='indicator'></div>)}

                                                    {/* shop bag icon */}
                                                    <svg width="15" height="18" viewBox="0 0 15 18" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.25 17.5H1.75C1.264 17.5 0.850667 17.3263 0.51 16.979C0.17 16.6323 0 16.2157 0 15.729V5.938C0 5.452 0.17 5.03867 0.51 4.698C0.850667 4.358 1.264 4.188 1.75 4.188H3.292C3.292 3.02133 3.70167 2.03167 4.521 1.219C5.34033 0.406333 6.33333 0 7.5 0C8.66667 0 9.66333 0.406333 10.49 1.219C11.316 2.03167 11.729 3.02133 11.729 4.188H13.25C13.736 4.188 14.1493 4.358 14.49 4.698C14.83 5.03867 15 5.452 15 5.938V15.729C15 16.2157 14.83 16.6323 14.49 16.979C14.1493 17.3263 13.736 17.5 13.25 17.5ZM5.042 4.188H9.979C9.979 3.52133 9.736 2.94833 9.25 2.469C8.764 1.98967 8.18767 1.75 7.521 1.75C6.84033 1.75 6.257 1.98967 5.771 2.469C5.285 2.94833 5.042 3.52133 5.042 4.188ZM7.5 10.959C8.58333 10.959 9.53467 10.5977 10.354 9.875C11.1733 9.153 11.583 8.37533 11.583 7.542C11.583 7.34733 11.4997 7.16667 11.333 7C11.1663 6.83333 10.965 6.75 10.729 6.75C10.535 6.75 10.3613 6.81267 10.208 6.938C10.0553 7.06267 9.94433 7.23633 9.875 7.459C9.72233 7.97233 9.42033 8.39233 8.969 8.719C8.51767 9.04567 8.028 9.209 7.5 9.209C6.972 9.209 6.48233 9.04567 6.031 8.719C5.57967 8.39233 5.28467 7.97233 5.146 7.459C5.09 7.26433 4.98233 7.09767 4.823 6.959C4.663 6.81967 4.479 6.75 4.271 6.75C4.035 6.75 3.837 6.82667 3.677 6.98C3.51767 7.13267 3.438 7.32 3.438 7.542C3.438 8.37533 3.844 9.153 4.656 9.875C5.46867 10.5977 6.41667 10.959 7.5 10.959Z" />
                                                    </svg>
                                                </div>
                                            </div>

                                            {/* theme items container */}
                                            <div className='theme-container'>
                                                {sortThemesByOwnership(this.props.userInventory, true).map((theme, index) => {
                                                    let themeOwned = this.props.userInventory.map((item) => item.value).includes(theme.slug);

                                                    return <ThemeItem key={index} slug={theme.slug} owned={themeOwned} selected={this.props.userTheme === theme.slug} special={Object.values(theme.accessories).length > 0} discount={theme.discount} className={theme.className} onClick={() => {
                                                        if (themeOwned) return this.props.setTheme(theme.slug);
                                                        else this.setState({ displayedContent: 'shop', previewedTheme: theme, returnToList: true });
                                                    }} />
                                                })}
                                            </div>
                                        </div>
                                    );
                                case 'shop':
                                    return <ShopContainer previewedTheme={this.state.previewedTheme} userInventory={this.props.userInventory} goBack={() => this.setState({ displayedContent: null, previewedTheme: null, returnToList: false })} returnToList={this.state.returnToList} setPreviewedTheme={(previewedTheme) => this.setState({ previewedTheme })} setTheme={this.props.setTheme} addTheme={this.props.addTheme} />;
                            }
                        })()}
                    </CSSTransition>
                </SwitchTransition>
            </>
        );
    }
}