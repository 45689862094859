const LocalStorage = require('../../base/LocalStorage');
const Defaults = require('../Defaults');

module.exports = () => LocalStorage.getValue('cachedTimetables', Defaults.default.cachedTimetables, true, true);

module.exports.getCollection = (neededTimetable, customTables = null) => {
    // splits the needed data into an object
    let timetableData = { collection: neededTimetable.split('/')[0], table: neededTimetable.split('/')[1] };

    if ((timetableData.collection === 'custom') && customTables) { // if the collection is custom and the customTables array has been provided, returns the found data
        // finds the needed table
        var table = customTables.find((table) => {
            return table.metadata.identifier === timetableData.table;
        });

        table['collection'] = { configuration: table.configuration }; // NEEDS TO BE IMPROVED!!! - adds the collection metadata to the table
        return table;
    } else { // else, try to find it in the module.exports array
        // finds the needed collection
        return module.exports().find((timetable) => {
            return timetable.collection.slug === timetableData.collection;
        });
    }
}
module.exports.getTable = (neededTimetable, customTables = null) => {
    console.log(neededTimetable, customTables);
    // splits the needed data into an object
    let timetableData = { collection: neededTimetable.split('/')[0], table: neededTimetable.split('/')[1] };

    if ((timetableData.collection === 'custom') && customTables) { // if the collection is custom and the customTables array has been provided, try to find it in there
        // finds the needed timetablem and then the table from its tables array
        return customTables?.find((table) => {
            return table.metadata.identifier === timetableData.table;
        });
    } else { // else, try to find it in the module.exports array
        // splits the needed data into an object
        let timetableData = { collection: neededTimetable.split('/')[0], table: neededTimetable.split('/')[1] };

        // finds the needed timetable and then the table from its tables array
        return module.exports().find((timetable) => {
            return timetable.collection.slug === timetableData.collection;
        })?.tables.find((table) => {
            return table.slug === timetableData.table;
        });
    }
}