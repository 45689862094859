import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import SubjectItem from '../items/SubjectItem';

import '../../stylesheet/subjects.css';

export default class SubjectContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <TransitionGroup className="subject-container" style={(this.props.bannerAdDisplayed && !this.props.keyboardShown) ? { marginTop: '170px' } : { marginTop: '130px' }}>
                {this.props.subjects.map((subject, index) => {
                    return (
                        <CSSTransition key={index} timeout={500} classNames="root-transition-item">
                            <SubjectItem name={subject.name} grades={subject.grades} index={index} onClick={() => this.props.setParentState({ displayModal: true, modalContent: 'subject', selectedSubject: index })} />
                        </CSSTransition>
                    );
                })}

                <div className='subject-add' onClick={this.props.addSubject}>
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z" />
                    </svg>

                    <p>Dodaj predmet</p>
                </div>
            </TransitionGroup>
        );
    }
}