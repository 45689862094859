/* eslint-disable default-case */
import React from 'react';

import '../../stylesheet/game.css';

var game = {
    canvasSize: { width: 6, height: 6 },
    snakeParts: [{ x: 0, y: 1 }, { x: 0, y: 0 }],
    snakeDirection: null,
    foodPosition: { x: 1, y: 3 },
    gameScore: 0,
};
var movementInterval = null;

class SnakeContainer extends React.Component {
    constructor() {
        super();

        this.state = {
            gameActive: false,
        };
    }

    snakeEyePosition = () => {
        switch (game.snakeDirection || 'down') {
            case 'up':
                return {
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                };
            case 'down':
                return {
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                };
            case 'right':
                return {
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                };
            case 'left':
                return {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                };
        }
    }

    renderCanvas = () => {
        var gameCanvas = [];

        for (let y = 0; y < game.canvasSize.height; y++) {
            gameCanvas.push([]);

            for (let x = 0; x < game.canvasSize.width; x++) {
                if (game.snakeParts.find((snakePart) => snakePart.x === x && snakePart.y === y)) {
                    if (game.snakeParts.find((snakePart, snakePartIndex) => snakePart.x === x && snakePart.y === y && snakePartIndex === 0)) gameCanvas[y].push('snake-head');
                    else gameCanvas[y].push('snake');
                } else if (game.foodPosition.x === x && game.foodPosition.y === y) gameCanvas[y].push('food');
                else gameCanvas[y].push('canvas');
            }
        }

        return gameCanvas.map((row, index) => <div key={index} className="game-canvas-row">{(() => {
            return row.map((cell, cellIndex) => {
                if (cell === 'snake-head') {
                    return (
                        <div key={cellIndex} className={`game-component game-component-snake snake-head`} style={this.snakeEyePosition()}>
                            <div className="snake-eye"></div>
                            <div className="snake-eye"></div>
                        </div>
                    );
                } else return <div key={cellIndex} className={`game-component game-component-${cell}`}></div>;
            });
        })()}</div>);
    }

    updateGame = () => {
        var newHead = null;
        
        switch (game.snakeDirection) {
            case 'left':
                newHead = { x: game.snakeParts[0].x - 1, y: game.snakeParts[0].y };
                break;
            case 'up':
                newHead = { x: game.snakeParts[0].x, y: game.snakeParts[0].y - 1 };
                game.snakeParts.unshift();
                break;
            case 'down':
                newHead = { x: game.snakeParts[0].x, y: game.snakeParts[0].y + 1 };
                game.snakeParts.unshift();
                break;
            case 'right':
                newHead = { x: game.snakeParts[0].x + 1, y: game.snakeParts[0].y };
                break;
        }

        if (newHead.x === game.foodPosition.x && newHead.y === game.foodPosition.y) {
            game.gameScore++;
            
            game.snakeParts.unshift(game.foodPosition);
            this.respawnFood();
        } else {
            game.snakeParts.unshift(newHead);
            game.snakeParts.pop();
        }

        this.forceUpdate();
    }

    endGame = () => {
        clearInterval(movementInterval);
        movementInterval = null;

        game = {
            canvasSize: { width: 6, height: 6 },
            snakeParts: [{ x: 0, y: 1 }, { x: 0, y: 0 }],
            snakeDirection: null,
            foodPosition: { x: 1, y: 3 },
            gameScore: 0,
        };

        this.forceUpdate();
    }

    switchDirection = (direction) => {
        if (game.snakeDirection === 'right' && direction === 'left') return;
        if (game.snakeDirection === 'left' && direction === 'right') return;
        if (game.snakeDirection === 'up' && direction === 'down') return;
        if (game.snakeDirection === 'down' && direction === 'up') return;
        
        if (game.snakeParts[0].x === 0 && direction === 'left') return this.endGame();
        if (game.snakeParts[0].x === game.canvasSize.width - 1 && direction === 'right') return this.endGame();
        if (game.snakeParts[0].y === 0 && direction === 'up') return this.endGame();
        if (game.snakeParts[0].y === game.canvasSize.height - 1 && direction === 'down') return this.endGame();

        if (game.snakeParts.find((snakePart) => {
            if (direction === 'right') return snakePart.x === game.snakeParts[0].x + 1 && snakePart.y === game.snakeParts[0].y;
            if (direction === 'left') return snakePart.x === game.snakeParts[0].x - 1 && snakePart.y === game.snakeParts[0].y;
            if (direction === 'up') return snakePart.x === game.snakeParts[0].x && snakePart.y === game.snakeParts[0].y - 1;
            if (direction === 'down') return snakePart.x === game.snakeParts[0].x && snakePart.y === game.snakeParts[0].y + 1;
            else return false;
        })) this.endGame();
        else {
            game.snakeDirection = direction;
            
            this.updateGame();
            if (movementInterval) clearInterval(movementInterval);
            movementInterval = setInterval(() => {
                this.switchDirection(game.snakeDirection);
            }, 750);
        }
    }
    respawnFood = () => {
        var foodPosition = { x: Math.floor(Math.random() * (game.canvasSize.width)), y: Math.floor(Math.random() * (game.canvasSize.height)) };

        if (game.snakeParts.find((snakePart) => snakePart.x === foodPosition.x && snakePart.y === foodPosition.y)) {
            this.respawnFood();
        } else game.foodPosition = foodPosition;
    }

    render() {
        return (
            <div className="game-container">
                <div className="game-controls">
                    {game.gameScore.toString().split('').map((score) => <p className="game-component game-component-info">{score}</p>)}
                </div>
                
                <div className="game-canvas">
                    {this.renderCanvas()}
                </div>

                <div className="game-controls">
                    <div className="game-component game-component-info" style={{ opacity: !['up', 'down'].includes(game.snakeDirection) ? 1 : .5 }} onClick={() => game.snakeDirection === 'up' || this.switchDirection('up')}>
                        <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M71.4107 24.6824C72.5928 24.8817 73.2989 25.3724 75.6783 27.6418C89.5401 40.8751 101.176 52.2377 101.468 52.805C102.205 54.2924 102.174 56.0559 101.391 57.5126C100.869 58.4633 100.148 59.1073 99.027 59.6287L98.1827 60.0274L89.8932 60.0581C85.334 60.0887 81.4502 60.1347 81.266 60.1807C80.4064 60.3801 80.4524 59.0307 80.4524 86.08C80.4524 110.768 80.4524 111.013 80.1454 111.841C79.7002 112.991 78.7485 114.034 77.6125 114.555L76.6914 115H70.4743H64.2572L63.3362 114.571C62.3384 114.095 61.4787 113.252 60.9261 112.209L60.573 111.55L60.4963 86.2486C60.4349 66.0997 60.3735 60.8708 60.22 60.6101C60.1125 60.4107 59.8669 60.2267 59.6827 60.1807C59.4985 60.1347 55.6147 60.0887 51.0555 60.0581L42.766 60.0274L41.9217 59.6287C40.8011 59.1073 40.0796 58.4633 39.5577 57.5126C38.7748 56.0559 38.7441 54.2924 39.4809 52.805C39.7265 52.3297 43.1651 48.9255 51.7156 40.6758C67.834 25.1271 67.4809 25.4644 68.3866 25.0657C69.3077 24.6517 70.4129 24.5137 71.4107 24.6824Z" />
                        </svg>
                    </div>
                </div>
                <div className="game-controls">
                    <div className="game-component game-component-info" style={{ opacity: !['left', 'right'].includes(game.snakeDirection) ? 1 : .5 }} onClick={() => game.snakeDirection === 'left' || this.switchDirection('left')}>
                        <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24.6819 68.5893C24.8812 67.4072 25.3719 66.7011 27.6414 64.3217C40.8747 50.4599 52.2372 38.8239 52.8046 38.5322C54.292 37.7954 56.0554 37.8261 57.5121 38.609C58.4628 39.1309 59.1069 39.8524 59.6282 40.973L60.0269 41.8173L60.0576 50.1068C60.0882 54.666 60.1342 58.5498 60.1802 58.734C60.3796 59.5936 59.0302 59.5476 86.0795 59.5476C110.767 59.5476 111.013 59.5476 111.841 59.8546C112.991 60.2998 114.033 61.2515 114.555 62.3875L115 63.3086V69.5257V75.7428L114.57 76.6638C114.095 77.6616 113.251 78.5213 112.209 79.0739L111.549 79.427L86.2481 79.5037C66.0992 79.5651 60.8703 79.6265 60.6096 79.78C60.4102 79.8875 60.2262 80.1331 60.1802 80.3173C60.1342 80.5015 60.0882 84.3853 60.0576 88.9445L60.0269 97.234L59.6282 98.0783C59.1069 99.1989 58.4628 99.9204 57.5121 100.442C56.0554 101.225 54.292 101.256 52.8046 100.519C52.3292 100.273 48.925 96.8349 40.6753 88.2844C25.1266 72.166 25.4639 72.5191 25.0652 71.6134C24.6512 70.6923 24.5132 69.5871 24.6819 68.5893Z" />
                        </svg>
                    </div>
                    <div className="game-component game-component-info" style={{ cursor: 'default', backgroundColor: 'var(--background-tertiary)' }}></div>
                    <div className="game-component game-component-info" style={{ opacity: !['right', 'left'].includes(game.snakeDirection) ? 1 : .5 }} onClick={() => game.snakeDirection === 'right' || this.switchDirection('right')}>
                        <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M115.318 71.4107C115.119 72.5928 114.628 73.2989 112.359 75.6783C99.1253 89.5401 87.7628 101.176 87.1954 101.468C85.708 102.205 83.9446 102.174 82.4879 101.391C81.5372 100.869 80.8931 100.148 80.3718 99.027L79.9731 98.1827L79.9424 89.8932C79.9118 85.334 79.8658 81.4502 79.8198 81.266C79.6204 80.4064 80.9698 80.4524 53.9205 80.4524C29.2327 80.4524 28.9873 80.4524 28.1593 80.1454C27.0093 79.7002 25.9665 78.7485 25.4452 77.6125L25.0005 76.6914L25.0005 70.4743V64.2572L25.4298 63.3362C25.9052 62.3384 26.7486 61.4787 27.7913 60.9261L28.4507 60.573L53.7519 60.4963C73.9008 60.4349 79.1297 60.3735 79.3904 60.22C79.5898 60.1125 79.7738 59.8669 79.8198 59.6827C79.8658 59.4985 79.9118 55.6147 79.9424 51.0555L79.9731 42.766L80.3718 41.9217C80.8931 40.8011 81.5372 40.0796 82.4879 39.5577C83.9446 38.7748 85.708 38.7441 87.1954 39.4809C87.6708 39.7265 91.075 43.1651 99.3247 51.7156C114.873 67.834 114.536 67.4809 114.935 68.3866C115.349 69.3077 115.487 70.4129 115.318 71.4107Z" />
                        </svg>
                    </div>
                </div>
                <div className="game-controls">
                    <div className="game-component game-component-info" style={{ opacity: !['down', 'up'].includes(game.snakeDirection) ? 1 : .5 }} onClick={() => game.snakeDirection === 'down' || this.switchDirection('down')}>
                        <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M68.5893 115.318C67.4072 115.118 66.7011 114.628 64.3217 112.358C50.4599 99.1249 38.8239 87.7623 38.5322 87.195C37.7954 85.7076 37.8261 83.9441 38.609 82.4874C39.1309 81.5367 39.8524 80.8927 40.973 80.3713L41.8173 79.9726L50.1068 79.9419C54.666 79.9113 58.5498 79.8653 58.734 79.8193C59.5936 79.6199 59.5476 80.9693 59.5476 53.92C59.5476 29.2322 59.5476 28.9869 59.8546 28.1588C60.2998 27.0088 61.2515 25.966 62.3875 25.4447L63.3086 25H69.5257H75.7428L76.6638 25.4294C77.6616 25.9047 78.5213 26.7481 79.0739 27.7908L79.427 28.4502L79.5037 53.7514C79.5651 73.9003 79.6265 79.1292 79.78 79.3899C79.8875 79.5893 80.1331 79.7733 80.3173 79.8193C80.5015 79.8653 84.3853 79.9113 88.9445 79.9419L97.234 79.9726L98.0783 80.3713C99.1989 80.8927 99.9204 81.5367 100.442 82.4874C101.225 83.9441 101.256 85.7076 100.519 87.195C100.273 87.6703 96.8349 91.0745 88.2844 99.3242C72.166 114.873 72.5191 114.536 71.6134 114.934C70.6923 115.348 69.5871 115.486 68.5893 115.318Z" />
                        </svg>
                    </div>
                </div>
            </div>
        );
    }
}
export default SnakeContainer;