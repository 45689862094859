import toml from '@iarna/toml';

var tomlTableConverter = (table) => {
    if (!table) return null;

    table = {
        type: table.type,
        name: table.metadata.name,
        table: table.table,
        configuration: table.configuration,
        periods: table.periods
    } // modifies the provided table object to a new object that will be used as a toml string

    let tomlTable = toml.stringify(table);
        tomlTable = tomlTable.replace(/^[ \t]+|^(?:[\t ]*(?:\r?\n|\r))+/gm, '').replace(/\s*,\s*/g, ',').replace(/\[ +/g, '[').replace(/ \]+/g, ']').replace(/ = +/g, '='); // sanitizes the toml string to be more efficient

    return tomlTable;
}
export default tomlTableConverter;