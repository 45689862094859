import React from "react";

import '../../stylesheet/elements/radioBar.css';

export default class RadioBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className='radio-bar-container' style={this.props.style}>
                {this.props.items.map((item, index) => (
                    <div key={index} className='radio-bar-item' style={{ opacity: (this.props.currentItem === index ? 1 : .25), boxShadow: (this.props.currentItem === index ? '0px 0px 10px rgb(0 0 0 / 25%)' : null), borderRadius: (this.props.currentItem === index ? '10px' : null) }} onClick={() => this.props.onUpdate(index)}>
                        {item}
                    </div>
                ))}
            </div>
        );
    }
}