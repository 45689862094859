import React from "react";

import '../../stylesheet/elements/radioButton.css';

export default class RadioButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <div className='radio-button' style={this.props.style} onClick={(event) => {
                if (!['svg', 'path'].includes(event.target.nodeName) && this.props.onClick) this.props.onClick(event);
            }}>
                <div>
                    {this.props.index == null || <p className='radio-button-index'>#{this.props.index + 1}</p>}
                    <p className='radio-button-name'>{this.props.name}</p>
                </div>

                <div>
                    <p className='radio-button-tooltip'>{this.props.tooltip}</p>

                    {!this.props.onClick || (
                        <svg className='radio-button-check' viewBox='0 0 48 48' xmlns="http://www.w3.org/2000/svg" onClick={(event) => this.props.onClick(event)}>
                            {this.props.value ? (
                                <path d="M24 33.15q3.85 0 6.5-2.65t2.65-6.5q0-3.85-2.65-6.5T24 14.85q-3.85 0-6.5 2.65T14.85 24q0 3.85 2.65 6.5t6.5 2.65Zm0 11.9q-4.35 0-8.2-1.625-3.85-1.625-6.725-4.5Q6.2 36.05 4.575 32.2 2.95 28.35 2.95 24t1.625-8.2q1.625-3.85 4.5-6.725Q11.95 6.2 15.8 4.55q3.85-1.65 8.15-1.65 4.4 0 8.275 1.65t6.725 4.525q2.85 2.875 4.5 6.725 1.65 3.85 1.65 8.25 0 4.3-1.65 8.15-1.65 3.85-4.525 6.725-2.875 2.875-6.725 4.5-3.85 1.625-8.2 1.625Zm0-4.55q6.85 0 11.675-4.825Q40.5 30.85 40.5 24q0-6.85-4.825-11.675Q30.85 7.5 24 7.5q-6.85 0-11.675 4.825Q7.5 17.15 7.5 24q0 6.85 4.825 11.675Q17.15 40.5 24 40.5ZM24 24Z" />
                            ) : (
                                <path d="M24 45.05q-4.35 0-8.2-1.625-3.85-1.625-6.725-4.5Q6.2 36.05 4.575 32.2 2.95 28.35 2.95 24t1.625-8.2q1.625-3.85 4.5-6.725Q11.95 6.2 15.8 4.55q3.85-1.65 8.15-1.65 4.4 0 8.275 1.65t6.725 4.525q2.85 2.875 4.5 6.725 1.65 3.85 1.65 8.25 0 4.3-1.65 8.15-1.65 3.85-4.525 6.725-2.875 2.875-6.725 4.5-3.85 1.625-8.2 1.625Zm0-4.55q6.85 0 11.675-4.825Q40.5 30.85 40.5 24q0-6.85-4.825-11.675Q30.85 7.5 24 7.5q-6.85 0-11.675 4.825Q7.5 17.15 7.5 24q0 6.85 4.825 11.675Q17.15 40.5 24 40.5ZM24 24Z" />
                            )}
                        </svg>
                    )}
                    
                    {!this.props.onCogClick || (
                        <svg className='radio-button-check' viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" onClick={(event) => this.props.onCogClick(event)}>
                            <path d="M16.3994 11C16.3994 10.839 16.3922 10.685 16.3778 10.524L17.7169 9.537C18.0048 9.327 18.084 8.935 17.904 8.627L16.5578 6.366C16.3778 6.058 15.989 5.932 15.6579 6.072L14.1101 6.709C13.8437 6.527 13.5629 6.366 13.2677 6.233L13.059 4.616C13.0158 4.266 12.7062 4 12.3463 4H9.66095C9.29379 4 8.98422 4.266 8.94103 4.616L8.73225 6.233C8.43708 6.366 8.15631 6.527 7.88994 6.709L6.34212 6.072C6.01095 5.932 5.6222 6.058 5.44222 6.366L4.09596 8.634C3.91598 8.942 3.99518 9.327 4.28314 9.544L5.6222 10.531C5.6078 10.685 5.6006 10.839 5.6006 11C5.6006 11.161 5.6078 11.315 5.6222 11.476L4.28314 12.463C3.99518 12.673 3.91598 13.065 4.09596 13.373L5.44222 15.634C5.6222 15.942 6.01095 16.068 6.34212 15.928L7.88994 15.291C8.15631 15.473 8.43708 15.634 8.73225 15.767L8.94103 17.384C8.98422 17.734 9.29379 18 9.65375 18H12.3391C12.699 18 13.0086 17.734 13.0518 17.384L13.2606 15.767C13.5557 15.634 13.8365 15.473 14.1029 15.291L15.6507 15.928C15.9818 16.068 16.3706 15.942 16.5506 15.634L17.8968 13.373C18.0768 13.065 17.9976 12.68 17.7097 12.463L16.3706 11.476C16.3922 11.315 16.3994 11.161 16.3994 11ZM11.0288 13.45C9.63935 13.45 8.50908 12.351 8.50908 11C8.50908 9.649 9.63935 8.55 11.0288 8.55C12.4182 8.55 13.5485 9.649 13.5485 11C13.5485 12.351 12.4182 13.45 11.0288 13.45Z" />
                        </svg>
                    )}
                </div>
            </div>
        );
    }
}