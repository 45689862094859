import React from 'react';

import '../../stylesheet/structure/footer.css';

export default class FooterButton extends React.Component {
    render() {
        return (
            // <div className='footer-button' onClick={this.props.onClick || null}>
            <svg className='footer-button pressable' style={this.props.style || null} onClick={this.props.onClick || null} viewBox="0 0 37 36" xmlns="http://www.w3.org/2000/svg">

                {(() => {
                    switch (this.props.type) {
                        case 'table':
                            return this.props.displayIndicator ? (
                                <>
                                    <path style={{ transform: 'translateY(-7px)' }} fillRule="evenodd" clipRule="evenodd" d="M31.7008 17.8693C31.1465 17.9554 30.5784 18 30 18C28.1002 18 26.3128 17.5184 24.7534 16.6705V31.1998H28.9218C30.4503 31.1998 31.7008 29.8798 31.7008 28.2665V17.8693ZM31.7008 11.7033C31.1699 11.8953 30.5972 12 30 12C27.2386 12 25 9.76142 25 7C25 6.2105 25.183 5.46373 25.5089 4.7998H28.9218C30.4503 4.7998 31.7008 6.1198 31.7008 7.73314V11.7033ZM19.2201 4.7998H8.07973C6.55131 4.7998 5.30078 6.1198 5.30078 7.73314V12.1331H20.2686C19.4586 10.6007 19 8.85392 19 7C19 6.24651 19.0758 5.51071 19.2201 4.7998ZM21.9745 15.0958H15.0271V31.1998H21.9745V15.0958ZM8.07973 31.1998C6.55131 31.1998 5.30078 29.8798 5.30078 28.2665V15.0665H12.2481V31.1998H8.07973Z" />
                                    <path style={{ transform: 'translateY(-7px)' }} d="M37 7C37 10.866 33.866 14 30 14C26.134 14 23 10.866 23 7C23 3.13401 26.134 0 30 0C33.866 0 37 3.13401 37 7Z" fill="var(--indicator-color)"/>

                                    <text style={{ fontSize: '8px', textAnchor: 'middle', fill: 'var(--text-secondary)' }} x="18" y="43">{this.props.label}</text>
                                </>
                            ) : (
                                <>
                                    <path style={{ transform: 'translateY(-7px)' }} d="M15.0271 15.0958H21.9745V31.1998H15.0271V15.0958ZM24.7534 31.1998H28.9218C30.4503 31.1998 31.7008 29.8798 31.7008 28.2665V15.0665H24.7534V31.1998ZM28.9218 4.7998H8.07973C6.55131 4.7998 5.30078 6.1198 5.30078 7.73314V12.1331H31.7008V7.73314C31.7008 6.1198 30.4503 4.7998 28.9218 4.7998ZM5.30078 28.2665C5.30078 29.8798 6.55131 31.1998 8.07973 31.1998H12.2481V15.0665H5.30078V28.2665Z" />
                                
                                    <text style={{ fontSize: '8px', textAnchor: 'middle', fill: 'var(--text-secondary)' }} x="18" y="43">{this.props.label}</text>
                                </>
                            );
                        case 'tables':
                            return this.props.displayIndicator ? (
                                <>
                                    <path style={{ transform: 'translateY(-7px)' }} fillRule="evenodd" clipRule="evenodd" d="M19.0036 6.71609C19.0012 6.81044 19 6.90508 19 7C19 10.1853 20.3539 13.0543 22.5175 15.0631C22.505 15.1828 22.4906 15.3015 22.4741 15.4192C22.4055 15.9113 22.2681 16.4091 22.0622 16.9126L30.1298 24.9802C30.4731 25.3235 30.7477 25.7355 30.9537 26.2161C31.1597 26.6967 31.2627 27.1774 31.2627 27.658C31.2627 28.1844 31.154 28.6822 30.9365 29.1514C30.7191 29.6205 30.4388 30.0153 30.0954 30.3358C29.7293 30.6791 29.3116 30.9423 28.8424 31.1254C28.3732 31.3085 27.9098 31.4 27.452 31.4C26.9943 31.4 26.5365 31.3027 26.0788 31.1082C25.6211 30.9137 25.2205 30.6333 24.8772 30.2671L16.7066 22.0622C16.226 22.2681 15.7568 22.4055 15.2991 22.4741C14.8414 22.5428 14.315 22.5771 13.7199 22.5771C11.271 22.5771 9.20546 21.7303 7.52328 20.0367C5.84109 18.343 5 16.2947 5 13.8915C5 13.1592 5.12016 12.3925 5.36047 11.5914C5.60078 10.7904 5.89259 10.0694 6.23589 9.42861L11.3511 14.5782L14.5782 11.5571L9.32562 6.30455C9.92068 5.98414 10.6244 5.68661 11.4369 5.41196C12.2494 5.13732 13.0104 5 13.7199 5C15.7312 5 17.4924 5.57203 19.0036 6.71609Z" />
                                    <path style={{ transform: 'translateY(-7px)' }} d="M37 7C37 10.866 33.866 14 30 14C26.134 14 23 10.866 23 7C23 3.13401 26.134 0 30 0C33.866 0 37 3.13401 37 7Z" fill="var(--indicator-color)" />

                                    <text style={{ fontSize: '8px', textAnchor: 'middle', fill: 'var(--text-secondary)' }} x="18" y="43">{this.props.label}</text>
                                </>
                            ) : (
                                <>
                                    <path style={{ transform: 'translateY(-7px)' }} d="M27.452 31.4C26.9943 31.4 26.5365 31.3027 26.0788 31.1082C25.6211 30.9137 25.2205 30.6333 24.8772 30.2671L16.7066 22.0622C16.226 22.2681 15.7568 22.4055 15.2991 22.4741C14.8414 22.5428 14.315 22.5771 13.7199 22.5771C11.271 22.5771 9.20546 21.7303 7.52328 20.0367C5.84109 18.343 5 16.2947 5 13.8915C5 13.1592 5.12016 12.3925 5.36047 11.5914C5.60078 10.7904 5.89259 10.0694 6.23589 9.42861L11.3511 14.5782L14.5782 11.5571L9.32562 6.30455C9.92068 5.98414 10.6244 5.68661 11.4369 5.41196C12.2494 5.13732 13.0104 5 13.7199 5C16.1917 5 18.2858 5.86398 20.0023 7.59194C21.7189 9.3199 22.5771 11.4198 22.5771 13.8915C22.5771 14.4179 22.5428 14.9272 22.4741 15.4192C22.4055 15.9113 22.2681 16.4091 22.0622 16.9126L30.1298 24.9802C30.4731 25.3235 30.7477 25.7355 30.9537 26.2161C31.1597 26.6967 31.2627 27.1774 31.2627 27.658C31.2627 28.1844 31.154 28.6822 30.9365 29.1514C30.7191 29.6205 30.4388 30.0153 30.0954 30.3358C29.7293 30.6791 29.3116 30.9423 28.8424 31.1254C28.3732 31.3085 27.9098 31.4 27.452 31.4Z" />
                                    
                                    <text style={{ fontSize: '8px', textAnchor: 'middle', fill: 'var(--text-secondary)' }} x="18" y="43">{this.props.label}</text>
                                </>
                            );
                        case 'settings':
                            return this.props.displayIndicator ? (
                                <>
                                    <path style={{ transform: 'translateY(-7px)' }} fillRule="evenodd" clipRule="evenodd" d="M25.6449 9.45806L26.9834 8.9072C27.6079 8.6432 28.341 8.8808 28.6804 9.4616L30.1897 11.9965C30.1268 11.9988 30.0635 12 30 12C28.132 12 26.5033 10.9757 25.6449 9.45806ZM19.1814 5C19.0622 5.64855 19 6.31701 19 7C19 12.5236 23.0712 17.0963 28.3766 17.881C28.38 17.9865 28.3817 18.0924 28.3817 18.2C28.3817 18.5036 28.3682 18.794 28.3274 19.0976L30.8525 20.9588C31.3955 21.368 31.5449 22.094 31.2055 22.6748L28.6668 26.9384C28.3274 27.5192 27.5943 27.7568 26.9699 27.4928L24.0511 26.2916C23.5488 26.6348 23.0194 26.9384 22.4628 27.1892L22.0691 30.2384C21.9876 30.8984 21.4039 31.4 20.7251 31.4H15.6614C14.9826 31.4 14.3988 30.8984 14.3174 30.2384L13.9237 27.1892C13.3671 26.9384 12.8376 26.6348 12.3353 26.2916L9.41656 27.4928C8.79208 27.7568 8.059 27.5192 7.71961 26.9384L5.18096 22.6748C4.84157 22.094 4.9909 21.3548 5.53393 20.9588L8.059 19.0976C8.03185 18.794 8.01827 18.5036 8.01827 18.2C8.01827 17.8964 8.03185 17.606 8.059 17.3156L5.53393 15.4544C4.9909 15.0452 4.84157 14.3192 5.18096 13.7384L7.71961 9.4616C8.059 8.8808 8.79208 8.6432 9.41656 8.9072L12.3353 10.1084C12.8376 9.7652 13.3671 9.4616 13.9237 9.2108L14.3174 6.1616C14.3988 5.5016 14.9826 5 15.6749 5H19.1814ZM13.5028 18.2C13.5028 20.7476 15.6342 22.82 18.2543 22.82C20.8744 22.82 23.0058 20.7476 23.0058 18.2C23.0058 15.6524 20.8744 13.58 18.2543 13.58C15.6342 13.58 13.5028 15.6524 13.5028 18.2Z" />
                                    <path style={{ transform: 'translateY(-7px)' }} d="M36.6992 7C36.6992 10.866 33.5652 14 29.6992 14C25.8332 14 22.6992 10.866 22.6992 7C22.6992 3.13401 25.8332 0 29.6992 0C33.5652 0 36.6992 3.13401 36.6992 7Z" fill="var(--indicator-color)"/>
                                    
                                    <text style={{ fontSize: '8px', textAnchor: 'middle', fill: 'var(--text-secondary)' }} x="18" y="43">{this.props.label}</text>
                                </>
                            ) : (
                                <>
                                    <path style={{ transform: 'translateY(-7px)' }} d="M28.3817 18.2C28.3817 17.8964 28.3682 17.606 28.341 17.3024L30.8661 15.4412C31.4091 15.0452 31.5584 14.306 31.219 13.7252L28.6804 9.4616C28.341 8.8808 27.6079 8.6432 26.9834 8.9072L24.0647 10.1084C23.5624 9.7652 23.0329 9.4616 22.4763 9.2108L22.0826 6.1616C22.0012 5.5016 21.4174 5 20.7386 5H15.6749C14.9826 5 14.3988 5.5016 14.3174 6.1616L13.9237 9.2108C13.3671 9.4616 12.8376 9.7652 12.3353 10.1084L9.41656 8.9072C8.79208 8.6432 8.059 8.8808 7.71961 9.4616L5.18096 13.7384C4.84157 14.3192 4.9909 15.0452 5.53393 15.4544L8.059 17.3156C8.03185 17.606 8.01827 17.8964 8.01827 18.2C8.01827 18.5036 8.03185 18.794 8.059 19.0976L5.53393 20.9588C4.9909 21.3548 4.84157 22.094 5.18096 22.6748L7.71961 26.9384C8.059 27.5192 8.79208 27.7568 9.41656 27.4928L12.3353 26.2916C12.8376 26.6348 13.3671 26.9384 13.9237 27.1892L14.3174 30.2384C14.3988 30.8984 14.9826 31.4 15.6614 31.4H20.7251C21.4039 31.4 21.9876 30.8984 22.0691 30.2384L22.4628 27.1892C23.0194 26.9384 23.5488 26.6348 24.0511 26.2916L26.9699 27.4928C27.5943 27.7568 28.3274 27.5192 28.6668 26.9384L31.2055 22.6748C31.5449 22.094 31.3955 21.368 30.8525 20.9588L28.3274 19.0976C28.3682 18.794 28.3817 18.5036 28.3817 18.2ZM18.2543 22.82C15.6342 22.82 13.5028 20.7476 13.5028 18.2C13.5028 15.6524 15.6342 13.58 18.2543 13.58C20.8744 13.58 23.0058 15.6524 23.0058 18.2C23.0058 20.7476 20.8744 22.82 18.2543 22.82Z" />
                                        
                                    <text style={{ fontSize: '8px', textAnchor: 'middle', fill: 'var(--text-secondary)' }} x="18" y="43">{this.props.label}</text>
                                </>
                            );
                        case 'themes':
                            return this.props.displayIndicator ? (
                                <>
                                    <path style={{ transform: 'translateY(-7px)' }} fillRule="evenodd" clipRule="evenodd" d="M25.2185 5.53355C25.3827 5.75359 25.4801 6.02597 25.4801 6.32V7.64H29.4401V11.969C28.4418 11.8578 27.5324 11.4526 26.8001 10.8421V10.28H26.2261C25.4624 9.40206 25 8.25503 25 7C25 6.48972 25.0764 5.9973 25.2185 5.53355ZM19.1814 5H8.32C7.59401 5 7 5.594 7 6.32V11.6C7 12.326 7.59401 12.92 8.32 12.92H20.7273C19.6339 11.2109 19 9.17948 19 7C19 6.31701 19.0622 5.64855 19.1814 5ZM23.091 15.56H13.6V30.08C13.6 30.8061 14.194 31.4 14.92 31.4H17.56C18.286 31.4 18.88 30.8061 18.88 30.08V18.2H29.4401V17.986C27.0407 17.8657 24.8439 16.9766 23.091 15.56Z" />
                                    <path style={{ transform: 'translateY(-7px)' }} d="M36.6992 7C36.6992 10.866 33.5652 14 29.6992 14C25.8332 14 22.6992 10.866 22.6992 7C22.6992 3.13401 25.8332 0 29.6992 0C33.5652 0 36.6992 3.13401 36.6992 7Z" fill="var(--indicator-color)"/>
                                    
                                    <text style={{ fontSize: '8px', textAnchor: 'middle', fill: 'var(--text-secondary)' }} x="18" y="43">{this.props.label}</text>
                                </>
                            ) : (
                                <>
                                    <path style={{ transform: 'translateY(-7px)' }} d="M25.4801 7.64V6.32C25.4801 5.594 24.8861 5 24.16 5H8.32C7.59401 5 7 5.594 7 6.32V11.6C7 12.326 7.59401 12.92 8.32 12.92H24.16C24.8861 12.92 25.4801 12.326 25.4801 11.6V10.28H26.8001V15.56H13.6V30.08C13.6 30.8061 14.194 31.4 14.92 31.4H17.56C18.286 31.4 18.88 30.8061 18.88 30.08V18.2H29.4401V7.64H25.4801Z" />
                                            
                                    <text style={{ fontSize: '8px', textAnchor: 'middle', fill: 'var(--text-secondary)' }} x="18" y="43">{this.props.label}</text>
                                </>
                            );
                        case 'home':
                            return this.props.displayIndicator ? (
                                <>
                                    <path style={{ transform: 'translateY(-7px)' }} fillRule="evenodd" clipRule="evenodd" d="M30 17.9888C29.8343 17.9963 29.6676 18 29.5 18C23.4249 18 18.5 13.0751 18.5 7C18.5 6.34514 18.5572 5.70365 18.6669 5.08025C18.4467 5.02675 18.2186 5 17.9825 5C17.6555 5 17.3285 5.0589 17.0015 5.17671C16.6745 5.29451 16.3591 5.43588 16.0555 5.6008L7.29635 12.2803C6.85255 12.5866 6.52555 12.9636 6.31533 13.4112C6.10511 13.8589 6 14.3301 6 14.8249V28.1839C6 29.0557 6.30949 29.8096 6.92847 30.4458C7.54745 31.0819 8.30073 31.4 9.18832 31.4H15.0044V20.5149H20.9606V31.4H26.7766C27.6642 31.4 28.4234 31.0819 29.054 30.4458C29.6847 29.8096 30 29.0557 30 28.1839V17.9888ZM25.2968 9.70902L28.0014 11.7716C26.8769 11.4187 25.9234 10.6793 25.2968 9.70902Z" />
                                    <path style={{ transform: 'translateY(-7px)' }} d="M36.1992 7C36.1992 10.866 33.0652 14 29.1992 14C25.3332 14 22.1992 10.866 22.1992 7C22.1992 3.13401 25.3332 0 29.1992 0C33.0652 0 36.1992 3.13401 36.1992 7Z" fill="var(--indicator-color)"/>
                                    
                                    <text style={{ fontSize: '8px', textAnchor: 'middle', fill: 'var(--text-secondary)' }} x="18" y="43">{this.props.label}</text>
                                </>
                            ) : (
                                <>
                                    <path style={{ transform: 'translateY(-7px)' }} d="M9.18832 31.4C8.30073 31.4 7.54745 31.0819 6.92847 30.4458C6.30949 29.8096 6 29.0557 6 28.1839V14.8249C6 14.3301 6.10511 13.8589 6.31533 13.4112C6.52555 12.9636 6.85255 12.5866 7.29635 12.2803L16.0555 5.6008C16.3591 5.43588 16.6745 5.29451 17.0015 5.17671C17.3285 5.0589 17.6555 5 17.9825 5C18.3328 5 18.6657 5.0589 18.981 5.17671C19.2963 5.29451 19.6058 5.43588 19.9095 5.6008L28.6686 12.2803C29.1124 12.6102 29.4453 12.993 29.6672 13.4289C29.889 13.8648 30 14.3301 30 14.8249V28.1839C30 29.0557 29.6847 29.8096 29.054 30.4458C28.4234 31.0819 27.6642 31.4 26.7766 31.4H20.9606V20.5149H15.0044V31.4H9.18832Z" />
                                                
                                    <text style={{ fontSize: '8px', textAnchor: 'middle', fill: 'var(--text-secondary)' }} x="18" y="43">{this.props.label}</text>
                                </>
                            );
                        case 'developer':
                            return this.props.displayIndicator ? (
                                <>
                                    <path style={{ transform: 'translateY(-7px)' }} fillRule="evenodd" clipRule="evenodd" d="M29.6848 17.9985C29.6233 17.9995 29.5617 18 29.5 18C25.7509 18 22.4398 16.1244 20.454 13.2603L20.3036 13.4107L25.2054 18.3125L20.2768 23.2411L22.6875 25.625L30 18.3125L29.6848 17.9985ZM6 18.3125L13.3125 25.5982L15.6964 23.2143L10.7946 18.3125L15.7232 13.3839L13.3125 11L6 18.3125Z" />
                                    <path style={{ transform: 'translateY(-7px)' }} d="M36.1992 7C36.1992 10.866 33.0652 14 29.1992 14C25.3332 14 22.1992 10.866 22.1992 7C22.1992 3.13401 25.3332 0 29.1992 0C33.0652 0 36.1992 3.13401 36.1992 7Z" fill="var(--indicator-color)"/>
                                    
                                    <text style={{ fontSize: '8px', textAnchor: 'middle', fill: 'var(--text-secondary)' }} x="18" y="43">{this.props.label}</text>
                                </>
                            ) : (
                                <>
                                    <path style={{ transform: 'translateY(-7px)' }} d="M13.3125 25.5982L6 18.3125L13.3125 11L15.7232 13.3839L10.7946 18.3125L15.6964 23.2143L13.3125 25.5982ZM22.6875 25.625L20.2768 23.2411L25.2054 18.3125L20.3036 13.4107L22.6875 11.0268L30 18.3125L22.6875 25.625Z" />

                                    <text style={{ fontSize: '8px', textAnchor: 'middle', fill: 'var(--text-secondary)' }} x="18" y="43">{this.props.label}</text>
                                </>
                            )
                        case 'profiles':
                            return this.props.displayIndicator ? (
                                <>
                                    <path style={{ transform: 'translateY(-7px)' }} fillRule="evenodd" clipRule="evenodd" d="M19.1783 5.01704C19.0612 5.66026 19 6.32299 19 7C19 10.7059 20.8326 13.9838 23.6411 15.9768C22.4322 17.3407 20.6669 18.2 18.7 18.2C15.0535 18.2 12.1 15.2465 12.1 11.6C12.1 7.9535 15.0535 5 18.7 5C18.8608 5 19.0203 5.00575 19.1783 5.01704ZM5.5 28.1C5.5 23.711 14.2945 21.5 18.7 21.5C23.1055 21.5 31.9 23.711 31.9 28.1V29.75C31.9 30.6575 31.1575 31.4 30.25 31.4H7.15C6.2425 31.4 5.5 30.6575 5.5 29.75V28.1Z" />
                                    <path style={{ transform: 'translateY(-7px)' }} d="M36.6992 7C36.6992 10.866 33.5652 14 29.6992 14C25.8332 14 22.6992 10.866 22.6992 7C22.6992 3.13401 25.8332 0 29.6992 0C33.5652 0 36.6992 3.13401 36.6992 7Z" fill="var(--indicator-color)"/>
                                    
                                    <text style={{ fontSize: '8px', textAnchor: 'middle', fill: 'var(--text-secondary)' }} x="18" y="43">{this.props.label}</text>
                                </>
                            ) : (
                                <>
                                    <path style={{ transform: 'translateY(-7px)' }} d="M18.7 18.2C22.3465 18.2 25.3 15.2465 25.3 11.6C25.3 7.9535 22.3465 5 18.7 5C15.0535 5 12.1 7.9535 12.1 11.6C12.1 15.2465 15.0535 18.2 18.7 18.2ZM18.7 21.5C14.2945 21.5 5.5 23.711 5.5 28.1V29.75C5.5 30.6575 6.2425 31.4 7.15 31.4H30.25C31.1575 31.4 31.9 30.6575 31.9 29.75V28.1C31.9 23.711 23.1055 21.5 18.7 21.5Z" />
                                    
                                    <text style={{ fontSize: '8px', textAnchor: 'middle', fill: 'var(--text-secondary)' }} x="18" y="43">{this.props.label}</text>
                                </>
                            )
                        case 'credits':
                            return this.props.displayIndicator ? (
                                <>
                                    <path style={{ transform: 'translateY(-7px)' }} fillRule="evenodd" clipRule="evenodd" d="M31.3955 17.8373C30.7797 17.9442 30.1464 18 29.5 18C23.4249 18 18.5 13.0751 18.5 7C18.5 6.31977 18.5617 5.65396 18.6799 5.00787C18.5209 5.00263 18.3609 5 18.2 5C16.3548 5 14.6311 5.34501 13.0292 6.03504C11.4272 6.72409 10.0336 7.6618 8.84818 8.84818C7.6618 10.0336 6.72409 11.4272 6.03504 13.0292C5.34501 14.6311 5 16.3548 5 18.2C5 20.0452 5.34501 21.7689 6.03504 23.3708C6.72409 24.9727 7.6618 26.3664 8.84818 27.5518C10.0336 28.7382 11.4272 29.6759 13.0292 30.365C14.6311 31.055 16.3548 31.4 18.2 31.4C20.0452 31.4 21.7689 31.055 23.3708 30.365C24.9727 29.6759 26.3664 28.7382 27.5518 27.5518C28.7382 26.3664 29.6759 24.9727 30.365 23.3708C31.055 21.7689 31.4 20.0452 31.4 18.2C31.4 18.0786 31.3985 17.9577 31.3955 17.8373ZM29.866 11.9868C29.2446 10.815 28.4732 9.76878 27.5518 8.84818C26.6584 7.95402 25.6466 7.20112 24.5166 6.58947C24.5056 6.72486 24.5 6.86177 24.5 7C24.5 9.76142 26.7386 12 29.5 12C29.6231 12 29.7451 11.9956 29.866 11.9868ZM19.4161 24.2219C19.0715 24.5664 18.6661 24.7387 18.2 24.7387C17.7338 24.7387 17.3285 24.5712 16.9839 24.2365C16.6384 23.9026 16.4657 23.5022 16.4657 23.035V18.7168C16.4657 18.2507 16.6384 17.8453 16.9839 17.5007C17.3285 17.1562 17.7338 16.984 18.2 16.984C18.6661 16.984 19.0715 17.1509 19.4161 17.4847C19.7616 17.8195 19.9343 18.22 19.9343 18.6861V23.0058C19.9343 23.472 19.7616 23.8774 19.4161 24.2219ZM19.5387 14.565C19.1737 14.9406 18.7274 15.1285 18.2 15.1285C17.6929 15.1285 17.2521 14.9406 16.8774 14.565C16.5017 14.1903 16.3139 13.7494 16.3139 13.2423C16.3139 12.7148 16.5017 12.2691 16.8774 11.9051C17.2521 11.5392 17.6929 11.3562 18.2 11.3562C18.7274 11.3562 19.1737 11.5392 19.5387 11.9051C19.9036 12.2691 20.0861 12.7148 20.0861 13.2423C20.0861 13.7494 19.9036 14.1903 19.5387 14.565Z" />
                                    <path style={{ transform: 'translateY(-7px)' }} d="M36.1992 7C36.1992 10.866 33.0652 14 29.1992 14C25.3332 14 22.1992 10.866 22.1992 7C22.1992 3.13401 25.3332 0 29.1992 0C33.0652 0 36.1992 3.13401 36.1992 7Z" />
                                    
                                    <text style={{ fontSize: '8px', textAnchor: 'middle', fill: 'var(--text-secondary)' }} x="18" y="43">{this.props.label}</text>
                                </>
                            ) : (
                                <>
                                    <path style={{ transform: 'translateY(-7px)' }} d="M18.2 24.7387C18.6661 24.7387 19.0715 24.5664 19.4161 24.2219C19.7616 23.8774 19.9343 23.472 19.9343 23.0058V18.6861C19.9343 18.22 19.7616 17.8195 19.4161 17.4847C19.0715 17.1509 18.6661 16.984 18.2 16.984C17.7338 16.984 17.3285 17.1562 16.984 17.5007C16.6384 17.8453 16.4657 18.2507 16.4657 18.7168V23.035C16.4657 23.5022 16.6384 23.9026 16.984 24.2365C17.3285 24.5712 17.7338 24.7387 18.2 24.7387ZM18.2 15.1285C18.7274 15.1285 19.1737 14.9406 19.5387 14.565C19.9036 14.1903 20.0861 13.7494 20.0861 13.2423C20.0861 12.7148 19.9036 12.2691 19.5387 11.9051C19.1737 11.5392 18.7274 11.3562 18.2 11.3562C17.6929 11.3562 17.2521 11.5392 16.8774 11.9051C16.5017 12.2691 16.3139 12.7148 16.3139 13.2423C16.3139 13.7494 16.5017 14.1903 16.8774 14.565C17.2521 14.9406 17.6929 15.1285 18.2 15.1285ZM18.2 31.4C16.3548 31.4 14.6311 31.055 13.0292 30.365C11.4272 29.6759 10.0336 28.7382 8.84818 27.5518C7.6618 26.3664 6.72409 24.9727 6.03504 23.3708C5.34501 21.7689 5 20.0453 5 18.2C5 16.3548 5.34501 14.6311 6.03504 13.0292C6.72409 11.4272 7.6618 10.0336 8.84818 8.84818C10.0336 7.6618 11.4272 6.72409 13.0292 6.03504C14.6311 5.34501 16.3548 5 18.2 5C20.0453 5 21.7689 5.34501 23.3708 6.03504C24.9727 6.72409 26.3664 7.6618 27.5518 8.84818C28.7382 10.0336 29.6759 11.4272 30.365 13.0292C31.055 14.6311 31.4 16.3548 31.4 18.2C31.4 20.0453 31.055 21.7689 30.365 23.3708C29.6759 24.9727 28.7382 26.3664 27.5518 27.5518C26.3664 28.7382 24.9727 29.6759 23.3708 30.365C21.7689 31.055 20.0453 31.4 18.2 31.4Z" />
                                    
                                    <text style={{ fontSize: '8px', textAnchor: 'middle', fill: 'var(--text-secondary)' }} x="18" y="43">{this.props.label}</text>
                                </>
                            )
                        default:
                            return null;
                    }
                })()}

                <rect x="13" y="36" width="10" height="3" rx="1.5" fill="var(--theme-primary)" style={{ opacity: (this.props.selected ? 1 : 0), transform: 'translateY(-7px)' }} />
            </svg>
            // </div>
        );
    }
}