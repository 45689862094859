import React from 'react';

import '../../stylesheet/settings.css';

class SettingsItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // finds the setting with the same value as the one in the table
            value: (() => {
                switch (this.props.type || 'increment') {
                    case 'toggle':
                        return this.props.value;
                    case 'increment':
                        return this.props.values.map((value, index) => {
                            return { index, name: value.name || null, value: value.value, default: (this.props.table.configuration[this.props.value] === value.value) } 
                        }).find((value) => value.default);
                    default:
                        return null;
                }
            })(),
        };
    }

    switchValue = (direction) => {
        let valueIndex, newValue;

        if (direction === 'backwards') { // if the direction is set to backwards, reverses the current array index, with protection
            valueIndex = this.props.values[this.state.value.index - 1] ? this.state.value.index - 1 : this.props.values.length - 1; 
            
            newValue = this.props.values[valueIndex];
            newValue = { index: valueIndex, name: newValue.name || null, value: newValue.value, default: newValue.default };
        } else if (direction === 'forwards') { // else increments the current array index, with protection
            valueIndex = this.props.values[this.state.value.index + 1] ? this.state.value.index + 1 : 0; 

            newValue = this.props.values[valueIndex];
            newValue = { index: valueIndex, name: newValue.name || null, value: newValue.value, default: newValue.default };
        }

        this.setState({ value: newValue }); // updates the state with the new value
        this.props.onUpdate(this.props.value, newValue.value); // updates the setting in the table
    }

    render() {
        return (
            <div className='settings-item' style={this.props.type === 'toggle' ? { cursor: 'pointer' } : null} onClick={this.props.type === 'toggle' ? (() => this.props.onUpdate(!this.props.value)) : null}>
                <div>
                    <p className='settings-title'>{this.props.name}</p>
                    
                    {(() => {
                        switch (this.props.type || 'increment') {
                            case 'toggle':
                                return <p className='settings-value'>{this.props.metaValue || this.props.value}</p>;
                            case 'increment':
                                return <p className='settings-value'>{this.state.value.name || this.state.value.value}</p>;
                            default:
                                return <p>oops</p>;
                        }
                    })()}
                </div>

                <div>
                    {(() => {
                        switch (this.props.type || 'increment') {
                            case 'toggle':
                                return (
                                    <svg style={{ width: '26px' }} viewBox='0 0 48 48' xmlns="http://www.w3.org/2000/svg">
                                        {this.props.value ? (
                                            <path d="M24 33.15q3.85 0 6.5-2.65t2.65-6.5q0-3.85-2.65-6.5T24 14.85q-3.85 0-6.5 2.65T14.85 24q0 3.85 2.65 6.5t6.5 2.65Zm0 11.9q-4.35 0-8.2-1.625-3.85-1.625-6.725-4.5Q6.2 36.05 4.575 32.2 2.95 28.35 2.95 24t1.625-8.2q1.625-3.85 4.5-6.725Q11.95 6.2 15.8 4.55q3.85-1.65 8.15-1.65 4.4 0 8.275 1.65t6.725 4.525q2.85 2.875 4.5 6.725 1.65 3.85 1.65 8.25 0 4.3-1.65 8.15-1.65 3.85-4.525 6.725-2.875 2.875-6.725 4.5-3.85 1.625-8.2 1.625Zm0-4.55q6.85 0 11.675-4.825Q40.5 30.85 40.5 24q0-6.85-4.825-11.675Q30.85 7.5 24 7.5q-6.85 0-11.675 4.825Q7.5 17.15 7.5 24q0 6.85 4.825 11.675Q17.15 40.5 24 40.5ZM24 24Z" />
                                        ) : (
                                            <path d="M24 45.05q-4.35 0-8.2-1.625-3.85-1.625-6.725-4.5Q6.2 36.05 4.575 32.2 2.95 28.35 2.95 24t1.625-8.2q1.625-3.85 4.5-6.725Q11.95 6.2 15.8 4.55q3.85-1.65 8.15-1.65 4.4 0 8.275 1.65t6.725 4.525q2.85 2.875 4.5 6.725 1.65 3.85 1.65 8.25 0 4.3-1.65 8.15-1.65 3.85-4.525 6.725-2.875 2.875-6.725 4.5-3.85 1.625-8.2 1.625Zm0-4.55q6.85 0 11.675-4.825Q40.5 30.85 40.5 24q0-6.85-4.825-11.675Q30.85 7.5 24 7.5q-6.85 0-11.675 4.825Q7.5 17.15 7.5 24q0 6.85 4.825 11.675Q17.15 40.5 24 40.5ZM24 24Z" />
                                        )}
                                    </svg>
                                );
                            case 'increment':
                                return (
                                    <>
                                        {/* increment */}
                                        <svg viewBox="0 0 14 8" xmlns="http://www.w3.org/2000/svg" onClick={() => this.switchValue('forwards')}>
                                            <path d="M0 6.47773L1.61143 8L6.85714 3.05533L12.1029 8L13.7143 6.47773L6.85714 0L0 6.47773Z" />
                                        </svg>
                                        
                                        {/* decrement */}
                                        <svg viewBox="0 0 14 8" xmlns="http://www.w3.org/2000/svg" onClick={() => this.switchValue('backwards')}>
                                            <path d="M14 1.52227L12.3886 0L7.14286 4.94467L1.89714 0L0.285714 1.52227L7.14286 8L14 1.52227Z" />
                                        </svg>
                                    </>
                                );
                            default:
                                return <p>oops</p>;
                        }
                    })()}
                </div>
            </div>
        );
    }
}
export default SettingsItem;