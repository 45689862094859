import React from "react";

import '../../stylesheet/elements/infoButton.css';

export default class InfoButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <div className="info-button" onClick={!this.props.disabled ? this.props.onClick : null} style={{ cursor: (this.props.clickable ? 'pointer' : null), border: (this.props.glow ? '3px solid rgb(255 204 77 / 70%)' : null), opacity: (this.props.disabled ? .5 : null) }}>
                <div className='info-button-content'>
                    <p>{this.props.title}</p>
                    <p style={{ fontStyle: (this.props.descriptionItalic ? 'italic' : null), color: (this.props.descriptionColor || null) }}>{this.props.description}</p>
                </div>

                <div className='info-button-icons' style={{ marginBottom: (this.props.title && !this.props.description ? 'auto' : null) }}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}