import React from "react";

import '../../stylesheet/categories.css';

export default class CategoryItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div key={this.props.index} className="category-item" style={{ color: (this.props.selected ? 'var(--text-primary)' : 'var(--text-secondary)' ) }} onClick={this.props.onClick}>
                {!this.props.displayIndicator || (<div className='indicator' style={{ backgroundColor: this.props.indicatorColor }}></div>)}
                
                {this.props.name}
            </div>
        );
    }
}