import React from 'react';
import { SwitchTransition, CSSTransition } from "react-transition-group";
import moment from 'moment';

import BreakIcon from '../icons/BreakIcon';
import AccessoryItem from '../items/AccessoryItem';

import SUBJECTS from '../../backend/configuration/Subjects';
import Themes from '../../backend/configuration/Themes';

import '../../stylesheet/periods.css';
import '../../stylesheet/accessories.css';
import '../../stylesheet/elements/periodPill.css';

var canDisplayInterstitial = true; // variable representing whether the interstitial ad can be shown
export default class PeriodContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        let tablePeriods = {
            currentPeriod: this.props.table?.getCurrentPeriod(this.props.date),
            previousPeriod: this.props.table?.getPreviousPeriod(this.props.date),
            followingPeriod: this.props.table?.getFollowingPeriod(this.props.date),
        };

        if (!tablePeriods.previousPeriod && !tablePeriods.currentPeriod && tablePeriods.followingPeriod) tablePeriods.currentPeriod = { index: -1, type: 'CLASS', startsAt: moment().set({ hours: '0', minutes: '0' }), endsAt: tablePeriods.followingPeriod.startsAt }; // adds the midnight preceeding period

        // display an interstitial ad, if possible
        if (canDisplayInterstitial) {
            this.props.showInterstitial('periods');
            canDisplayInterstitial = false; // don't display another interstitial ad until changed

            setTimeout(() => { canDisplayInterstitial = true; }, (60000 * 15)); // allow the interstitial ad to be displayed after 10 minutes
        }

        let perpx = 2.5/1080;
        let scaleRatio = perpx * window.screen.width;
        return (
            <div className='period-container' style={{ transform: `scale(${(scaleRatio > 1) ? 1 : scaleRatio})` }}>
                {/* theme accessory item */}
                <SwitchTransition>
                    <CSSTransition key={this.props.userTheme}
                        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                        classNames='content-item'>

                        {Themes[this.props.userTheme].accessories.peek ? (
                            <AccessoryItem type='peek' data={Themes[this.props.userTheme].accessories.peek} position={Themes[this.props.userTheme].accessories.peek.position} source={Themes[this.props.userTheme].accessories.peek.source} size={Themes[this.props.userTheme].accessories.peek.size} offsetY={Themes[this.props.userTheme].accessories.peek.offsetY} />
                        ) : <div></div>}
                    </CSSTransition>
                </SwitchTransition>

                <div className='period-item previous-period' key='previous'>
                    <SwitchTransition>
                        <CSSTransition key={tablePeriods.previousPeriod?.periodTitle || null}
                            addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                            classNames='content-item'>

                            <p className='period-name'>{tablePeriods.previousPeriod ? (SUBJECTS[tablePeriods.previousPeriod.periodTitle]?.value || tablePeriods.previousPeriod.periodTitle) : null}</p>
                        </CSSTransition>
                    </SwitchTransition>

                    {tablePeriods.previousPeriod?.index === -1 || <p className='period-time'>{tablePeriods.previousPeriod ? tablePeriods.previousPeriod.endsAt.fromNow().replace('jedne minute', 'jednu minutu').replace('jednog sata', 'jedan sat') : null}</p>}
                </div>
                <div className='period-item current-period' key='current'>
                    <SwitchTransition>
                        <CSSTransition key={tablePeriods.currentPeriod?.type + tablePeriods.currentPeriod?.periodTitle}
                            addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                            classNames='content-item'>
                                
                            <p className='current-period-name'>
                                {(() => {
                                    if (tablePeriods.currentPeriod) { // if there is a current period
                                        if (tablePeriods.currentPeriod.type === 'CLASS') return (SUBJECTS[tablePeriods.currentPeriod.periodTitle]?.value || tablePeriods.currentPeriod.periodTitle) || null; // if the period is a class, returns the period title
                                        else return <BreakIcon type={tablePeriods.currentPeriod.type} size={35} />; // if the period is a break, returns the break icon
                                    } else return null; // if there is no current period, returns null
                                })()}
                            </p>
                        </CSSTransition>
                    </SwitchTransition>

                    <p className='current-period-time'>{tablePeriods.currentPeriod ? moment((tablePeriods.currentPeriod.endsAt.valueOf() - moment(this.props.date).valueOf()) - 3600000).format('HH:mm:ss') : null}</p>

                    <div className='period-overlay'>
                        <div className='current-period-information'>
                            {(() => {
                                let currentPeriod = SUBJECTS[tablePeriods[tablePeriods.currentPeriod?.type === 'CLASS' ? 'currentPeriod' : 'followingPeriod']?.periodTitle];
                                let previousPeriod = SUBJECTS[tablePeriods.previousPeriod?.periodTitle];

                                if (currentPeriod && this.props.userTable.startsWith('pgz')) {
                                    let floor = currentPeriod.additional ? currentPeriod.additional[this.props.userTable.split('/')[0]] : null;
                                    let previousFloor = previousPeriod?.additional ? previousPeriod?.additional[this.props.userTable.split('/')[0]] : null;

                                    return (
                                        <p>
                                            {(previousFloor !== undefined && currentPeriod.type !== 'CLASS') ? (
                                                <span>
                                                    {(() => {
                                                        if (floor === previousFloor) return;
                                                        else if (floor > previousFloor) return '▲ ';
                                                        else return '▼ ';
                                                    })()}
                                                </span>
                                            ) : null}

                                            <span>{floor > 0 ? `${floor}. sprat` : 'Prizemlje'}</span>
                                        </p>
                                    );
                                } else return <p>{null}</p>;
                            })()}
                            
                            <p>{tablePeriods.currentPeriod ? moment(tablePeriods.currentPeriod.endsAt).format('HH:mm') : null}</p>
                        </div>

                        <div className='period-break-container'>
                            <SwitchTransition>
                                <CSSTransition key={tablePeriods.currentPeriod?.type}
                                    addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                                    classNames='content-item'>
                                    
                                    <>
                                        {(() => {
                                            if (tablePeriods.currentPeriod) { // if there is a current period
                                                if (tablePeriods.currentPeriod.type === 'CLASS') return <BreakIcon type={this.props.table?.getFollowingPeriod(this.props.date, false)?.type} />; // if the period is a class, returns the default icon
                                                else return <BreakIcon />; // if the period is a break, returns the break icon
                                            } else return null; // if there is no current period, returns null
                                        })()}
                                    </>
                                </CSSTransition>
                            </SwitchTransition>
                        </div>

                        {/* period percentage */}
                        <div className='period-percentage-container'>
                            <div style={{ width: tablePeriods.currentPeriod ? `${((new Date(tablePeriods.currentPeriod?.endsAt.toDate() - this.props.date.getTime()).getTime() / 1000) / (tablePeriods.currentPeriod?.length * 60)) * 100}%` : null }}></div>
                        </div>
                    </div>
                </div>
                <div className='period-item following-period' key='following'>
                    <SwitchTransition>
                        <CSSTransition key={tablePeriods.followingPeriod?.periodTitle || null}
                            addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                            classNames='content-item'>

                            <p className='period-name'>{tablePeriods.followingPeriod ? (SUBJECTS[tablePeriods.followingPeriod.periodTitle]?.value || tablePeriods.followingPeriod.periodTitle) : null}</p>
                        </CSSTransition>
                    </SwitchTransition>

                    <p className='period-time'>{tablePeriods.followingPeriod ? tablePeriods.followingPeriod.startsAt.fromNow().replace('jedne minute', 'jednu minutu').replace('jednog sata', 'jedan sat') : null}</p>
                </div>
            </div>
        );
    }
}