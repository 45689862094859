import moment from 'moment';

class Table {
    constructor(tableConfiguration = {
        startsAt: '07:25',
        shortBreakLength: 5,
        longBreakLength: 15,
        longBreakAfter: 3,
        classLength: 45,
        classAmount: 8
    }, dateOverride = new Date(), userTable) {
        this.configuration = tableConfiguration;
        this.date = dateOverride;
        this.userTable = userTable;
    }

    constructTable = () => {
        var tableArray = [[], [], [], [], []]; // i hate Array(), and myself
        this.table = this.enhanceTable(tableArray);

        return this;
    }

    enhanceTable = (tableArray) => {
        for (let i = 0; i < tableArray.length; i++) { // day of the week for loop

            // sets up the start date using the configuration
            var startDate = moment(this.date);

            // sets the date day to the corresponding loop day of the week
            var currentDay = startDate.day();
            var dayDistance = (i - currentDay) + 1; // distance from the current day to the loop day, adds one since sunday is considered 0
            startDate.set('day', startDate.day() + dayDistance);

            startDate.set('hours', this.configuration.startsAt.split(':')[0]);
            startDate.set('minutes', this.configuration.startsAt.split(':')[1]);
            startDate.set('seconds', 0); // resets the seconds for accuracy

            // tableArray[i].push({ index: 0, startsAt: moment(this.date).set({ day: startDate.day() + dayDistance, hours: '0', minutes: '0' }), endsAt: startDate, type: 'CLASS', length: 10 })

            var extraterrestrialIndex = 0;
            if (this.userTable.type === 'custom') { // for custom tables

                const lengths = this.userTable.periods.map((day) => day.length); // used to get the longest array in the week
                
                for (let j = 0; j <= (this.userTable.periods[lengths.indexOf(Math.max(...lengths))].length - 1); j++) { // classes for loop

                    let currentPeriod = this.userTable.periods[i][j];
                    if (currentPeriod) {
                        let endDate = startDate.clone();
                        endDate.add(currentPeriod.length, 'minutes');

                        tableArray[i].push({ index: extraterrestrialIndex, startsAt: startDate, endsAt: endDate, type: currentPeriod.type, length: currentPeriod.length, periodTitle: currentPeriod.periodTitle });
                        extraterrestrialIndex += 1; // funny way of saying global index :)

                        startDate = endDate.clone();
                    }
                }
            } else { // only for automatic custom and school tables
                for (let j = 0; j <= (this.configuration.classAmount - 1); j++) { // classes for loop
    
                    let endDate = startDate.clone();
                        endDate.add(this.configuration.classLength, 'minutes');
    
                    tableArray[i].push({ index: extraterrestrialIndex, startsAt: startDate, endsAt: endDate, type: 'CLASS', length: this.configuration.classLength });
                    extraterrestrialIndex += 1; // funny way of saying global index :)
    
                    if (j !== (this.configuration.classAmount - 1)) { // add a break, if not the last class
                        startDate = endDate.clone();
                        let breakEndDate = endDate.clone(); // i'm creating a new variable for break end date, because of unknown issues with context. didn't want to mess around with that
    
                        let longBreak = Array.isArray(this.configuration.longBreakAfter) ? (this.configuration.longBreakAfter.find((longBreak) => longBreak.period === (j + 1))) : { period: this.configuration.longBreakAfter, length: this.configuration.longBreakLength };
                        if (longBreak?.period === (j + 1)) { // if the current class is the one the long break is after, add the long break
                            breakEndDate.add(longBreak.length, 'minutes'); // adds the set long break length to the break end date
    
                            tableArray[i].push({ index: extraterrestrialIndex, startsAt: startDate, endsAt: breakEndDate, type: 'LONG_BREAK', length: longBreak.length });
                            extraterrestrialIndex += 1;
                        } else { // else, add short break
                            breakEndDate.add(this.configuration.shortBreakLength, 'minutes'); // adds the set short break length to the break end date
    
                            tableArray[i].push({ index: extraterrestrialIndex, startsAt: startDate, endsAt: breakEndDate, type: 'SHORT_BREAK', length: this.configuration.shortBreakLength });
                            extraterrestrialIndex += 1;
                        }
    
                        startDate = breakEndDate.clone(); // sets the start date to the break end date
                    } else startDate = endDate.clone(); // if it's the last class, set the start date to the end date
                }
            }
        }

        return tableArray; // sets the table array to the class table property
    }

    // injects the classes into the table
    injectClasses = (classes) => {
        // [['class','class','class'], ['class', ...], ...]

        // if the current table is custom, skip the injection
        if (this.userTable.type === 'custom') return this;

        for (let i = 0; i < this.table.length; i++) {
            let filteredPeriods = this.table[i].filter((period) => period.type === 'CLASS');

            // assigns class information to the filtered periods array
            for (let j = 0; j < filteredPeriods.length; j++) {
                // assigns the class information to the period in the table
                this.table[i].find((period) => period.index === filteredPeriods[j].index)['periodTitle'] = classes[i][j] || '';
            }
            
            // removes all unneeded periods
            this.table[i] = this.table[i].map((period) => {
                // checks if the class has its own assigned class in the classes array, if not returns null
                if (period.type === 'CLASS' && period.periodTitle === undefined) return null;
                else return period;
            }).filter((period) => period);

            // just cleanup after the removal of unneeded periods
            if (this.table[i][this.table[i].length - 1].type !== 'CLASS') {
                this.table[i][this.table[i].length - 1] = null;
                this.table[i] = this.table[i].filter((period) => period);
            }
        }

        return this;
    }

    // gets the provided day of the week
    getDay = (day) => { return this.table[day - 1] || null; }

    getCurrentPeriod = (date) => {
        date = moment(date.getTime());
        let currentTable = this.getDay(date.day());

        return currentTable?.find((period) => {
            return date.isBetween(period.startsAt, period.endsAt);
        }) || null; // gets the period between the starting and ending dates, if it doesn't exist returns null
    }

    getPreviousPeriod = (date) => {
        date = moment(date.getTime());
        let currentTable = this.getDay(date.day());

        var previousPeriods = currentTable?.filter((period) => period.type === 'CLASS').filter((period) => date.isAfter(period.endsAt)); // gets all periods before the provided date
        return previousPeriods ? (previousPeriods[previousPeriods.length - 1] || null) : null; // gets the last period in the array
    }
    getFollowingPeriod = (date, restrictToClasses = true) => {
        date = moment(date.getTime());
        let currentTable = this.getDay(date.day());

        return currentTable?.filter((period) => restrictToClasses ? (period.type === 'CLASS') : true).find((period) => {
            return date.isBefore(period.startsAt);
        }) || null; // gets the period after the ending date, if it doesn't exist returns null
    }

    // cleans up all blank periods
    cleanupPeriods = () => {
        if (this.userTable.type === 'auto' || this.userTable.type === 'custom') return this; // do not clean up custom-made tables

        if (this.table.every((day) => day[0].periodTitle === '')) { // if every first period of the week is blank
            this.table.forEach((day) => {
                day.shift(); day.shift(); // removes the first period and break
                day.forEach((period) => { period.index = period.index - 2 }); // makes up the offset for the removal of the previous two elements
            }); // removes every first period and break of the week 
        }
        return this;
    }
}
export default Table;