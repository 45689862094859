import moment from 'moment';
import { v4 as UUID } from 'uuid';

import Settings from './configuration/Settings';
import Themes from './configuration/Themes';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    userTheme: 'dark',
    userTable: 'pgz/iv1',
    userInventory: Object.values(Themes).map((theme, index) => { theme['slug'] = Object.keys(Themes)[index]; return theme; })
        .filter((theme) => theme.pricing.value === 0).map((theme) => { return { type: 'theme', value: theme.slug } }),
    visited: {},
    customTables: [
        { type: 'auto', table: [[], [], [], [], []], configuration: Settings.default.school, metadata: { name: null, identifier: UUID(), createdAt: new Date().getTime() } },
        { type: 'auto', table: [[], [], [], [], []], configuration: Settings.default.school, metadata: { name: null, identifier: UUID(), createdAt: new Date().getTime() } },
        { type: 'custom', periods: [[
            {
                index: 0,
                length: 30,
                periodTitle: 'bos',
                type : 'CLASS'
            },
            {
                index: 1,
                length: 5,
                type : 'SHORT_BREAK'
            },
            {
                index: 2,
                length: 45,
                periodTitle: 'nje',
                type : 'CLASS'
            },
            {
                index: 3,
                length: 5,
                type : 'SHORT_BREAK'
            },
            {
                index: 4,
                length: 45,
                periodTitle: 'mat',
                type : 'CLASS'
            },
        ], [
            {
                index: 0,
                startsAt: '07:25',
                length: 45,
                periodTitle: 'eng',
                type : 'CLASS'
            },
            {
                index: 1,
                length: 5,
                type : 'SHORT_BREAK'
            },
            {
                index: 2,
                length: 45,
                periodTitle: 'eng',
                type : 'CLASS'
            },
        ], [
            {
                index: 0,
                startsAt: '10:02',
                length: 45,
                periodTitle: 'inf',
                type : 'CLASS'
            },
            {
                index: 1,
                length: 5,
                type : 'SHORT_BREAK'
            },
            {
                index: 2,
                length: 45,
                periodTitle: 'lik',
                type : 'CLASS'
            },
            {
                index: 3,
                length: 5,
                type : 'SHORT_BREAK'
            },
            {
                index: 4,
                length: 45,
                periodTitle: 'muz',
                type : 'CLASS'
            },
        ], [
            {
                index: 0,
                startsAt: '07:25',
                length: 45,
                periodTitle: 'bos',
                type : 'CLASS'
            },
            {
                index: 1,
                length: 5,
                type : 'SHORT_BREAK'
            },
            {
                index: 2,
                length: 45,
                periodTitle: 'mat',
                type : 'CLASS'
            },
            {
                index: 3,
                length: 5,
                type : 'SHORT_BREAK'
            },
            {
                index: 4,
                length: 45,
                periodTitle: 'inf',
                type : 'CLASS'
            },
        ], [
            {
                index: 0,
                startsAt: '07:25',
                length: 45,
                periodTitle: 'geo',
                type : 'CLASS'
            },
            {
                index: 1,
                length: 5,
                type : 'SHORT_BREAK'
            },
            {
                index: 2,
                length: 45,
                periodTitle: 'hem',
                type : 'CLASS'
            },
            {
                index: 3,
                length: 5,
                type : 'SHORT_BREAK'
            },
            {
                index: 4,
                length: 45,
                periodTitle: 'anes',
                type : 'CLASS'
            },
        ], [
            {
                index: 0,
                startsAt: '07:25',
                length: 45,
                periodTitle: 'tje',
                type : 'CLASS'
            },
            {
                index: 1,
                length: 5,
                type : 'SHORT_BREAK'
            },
            {
                index: 2,
                length: 45,
                periodTitle: 'tje',
                type : 'CLASS'
            },
            {
                index: 3,
                length: 5,
                type : 'SHORT_BREAK'
            },
            {
                index: 4,
                length: 45,
                periodTitle: 'his',
                type : 'CLASS'
            },
        ]], configuration: { startsAt: '10:02' }, metadata: { name: null, identifier: UUID(), createdAt: new Date().getTime() } },
    ],
    profiles: [
        { identifier: UUID(), subjects: [], metadata: { name: null, createdAt: new Date() } }
    ],
    profile: () => {
        return { identifier: UUID(), subjects: [], metadata: { name: null, createdAt: new Date() } };
    },
    requestReviewAfter: moment().add({ days: 1 }).toDate(),
    analyticsIdentifier: UUID(),

    cachedTimetables: [
        { // Prva gimnazija
            version: '1.0',
            collection: {
                slug: 'pgz',
                title: 'Prva gimnazija',
                configuration: {
                    startsAt: '11:45',
                    shortBreakLength: 5,
                    longBreakLength: 15,
                    longBreakAfter: [{ period: 2, length: 10 }, { period: 4, length: 15 }],
                    classLength: 45,
                    classAmount: 8
                }
            },
            tables: [
                {
                    slug: 'ii1',
                    title: 'II-1',
                    table: [
                        ['bio', 'nje', 'mat', 'his', 'muz', 'tje'],
                        ['', 'tur', 'nje', 'geo', 'eng', 'fiz', 'inf', 'bos'],
                        ['', 'tur', 'vje', 'geo', 'coz', 'hem', 'bos', 'bos'],
                        ['bio', 'fiz', 'mat', 'mat', 'tje', 'eng', 'bos', ''],
                        ['', 'eti', 'his', 'eng', 'muz', 'mat', 'inf', 'hem'],
                    ],
                },
                {
                    slug: 'ii2',
                    title: 'II-2',
                    table: [
                        ['', 'mat', 'fiz', 'tje', 'geo', 'his', 'muz', 'bos'],
                        ['', 'Nje/Tur/Ara', 'bio', 'eng', 'bos', 'bos'],
                        ['', 'Nje/Tur/Ara', 'geo', 'coz', 'hem', 'fiz', 'tje'],
                        ['bos', 'eng', 'his', 'bio', 'inf', 'mat', 'vje'],
                        ['', 'eti', 'inf', 'hem', 'eng', 'muz', 'mat', 'mat'],
                    ],
                },
                {
                    slug: 'ii3',
                    title: 'II-3',
                    table: [
                        ['', 'mat', 'hem', 'muz', 'fiz', 'geo', 'tje', 'his'],
                        ['', 'tur', 'nje', 'mat', 'mat','eng', 'geo'],
                        ['', 'tur', 'fiz', 'vje', 'coz', 'bos', 'bos', 'hem'],
                        ['', 'bos', 'bio', 'inf', 'eng', 'inf', 'nje', 'tje'],
                        ['', 'eti', 'mat', 'bio', 'his', 'eng', 'muz', 'bos'],
                    ],
                },
                {
                    slug: 'ii4',
                    title: 'II-4',
                    table: [
                        ['', 'muz', 'inf', 'fiz', 'hem', 'mat', 'mat', 'tje'],
                        ['', 'tur', 'eng', 'bos', 'inf', 'mat', 'fiz', 'geo'],
                        ['', 'tur', 'eng', 'bos', 'coz', 'tje', 'vje', 'mat'],
                        ['', 'geo', 'eng', 'his', 'bos', 'bos', 'bio', 'nje'],
                        ['', 'eti', 'muz', 'nje', 'hem', 'his', 'bio'],
                    ],
                },
                {
                    slug: 'ii5',
                    title: 'II-5',
                    table: [
                        ['', 'geo', 'vje', 'mat', 'his', 'muz', 'fiz', 'eng'],
                        ['', 'tur', 'inf', 'nje', 'bos', 'bos', 'coz', 'mat'],
                        ['', 'tur', 'inf', 'mat', 'mat', 'bos', 'hem', 'tje'],
                        ['', 'eng', 'geo', 'fiz', 'bos', 'bio', 'tje'],
                        ['', 'eti', 'eng', 'his', 'nje', 'bio', 'hem', 'muz'],
                    ],
                },
                {
                    slug: 'iv1',
                    title: 'IV-1',
                    table: [
                        ['', '', 'fiz', 'hem', 'tje', 'coz', 'eng', 'lat'],
                        ['nje', 'fiz', 'fil', 'hem', 'kul', 'bos', 'bos'],
                        ['nje', 'bos', 'eng', 'fil', 'lat', 'mat', 'mat'],
                        ['', 'bio', 'fiz', 'tje', 'mat', 'vje'],
                        ['', 'bio', 'fil', 'eng', 'bio', 'hem'],
                    ],
                },
                {
                    slug: 'iv5',
                    title: 'IV-5',
                    table: [
                        ['', 'inf', 'tje', 'lat', 'mat', 'bos', 'bos'],
                        ['', 'eng', 'lat', 'mat', 'fiz', 'fil', 'bos'],
                        ['', 'eng', 'nje', 'fiz', 'tje', 'kul', 'fil'],
                        ['', 'eng', 'inf', 'fil', 'vje', 'nje', 'mat', 'mat'],
                        ['', '', 'coz', 'nacgeo', 'nacgeo', 'mat', 'fiz'],
                    ],
                },
            ]
        },
    ],

    offlineAnalytics: {},
}