import React from 'react';
import moment from 'moment';
import { SwitchTransition, CSSTransition } from "react-transition-group";

import SUBJECTS from '../../backend/configuration/Subjects';
import BreakIcon from '../icons/BreakIcon';

export default class PeriodPill extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentDisplay: 'time',
        };
    }

    componentDidMount = () => {
        var cycleDisplay = (type) => {
            if (!this.props.currentPeriod?.periodTitle) type = 'time'; // if the current period title does not exist, overwrite the cycle type to time

            this.setState({ currentDisplay: type });
            setTimeout(() => cycleDisplay((type === 'subject') ? 'time' : 'subject'), (type === 'subject' ? 2500 : 10000));
        }
        cycleDisplay('time'); // start the display cycle
    }

    render() {
        return (
            <div className='period-pill-container'>
                <SwitchTransition>
                    <CSSTransition key={this.state.currentDisplay}
                        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                        classNames='content-item'>

                        {this.state.currentDisplay === 'subject' ? (
                            <p className='period-pill-text'>{(() => {
                                if (this.props.currentPeriod?.type === 'CLASS') return (SUBJECTS[this.props.currentPeriod?.periodTitle]?.value || this.props.currentPeriod?.periodTitle);
                                else return <BreakIcon type={this.props.currentPeriod?.type} size={25} />;
                            })()}</p>
                        ) : (
                            <p className='period-pill-text'>{moment((this.props.currentPeriod?.endsAt.valueOf() - moment(this.props.date).valueOf()) - 3600000).format('HH:mm:ss')}</p>
                        )}
                    </CSSTransition>
                </SwitchTransition>
                
                <div className='period-pill-progress' style={{ width: this.props.currentPeriod ? `${((new Date(this.props.currentPeriod?.endsAt.toDate() - this.props.date.getTime()).getTime() / 1000) / (this.props.currentPeriod?.length * 60)) * 100}%` : null }}></div>
            </div>
        );
    }
}