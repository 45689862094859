module.exports = {
    // languages
    'bos': { value: 'Bosanski', additional: { 'pgz': 2 } },
    'eng': { value: 'Engleski', additional: { 'pgz': 3 } },
    'nje': { value: 'Njemački', additional: { 'pgz': 3 } },
    'tur': { value: 'Turski', additional: null },
    'lat': { value: 'Latinski', additional: { 'pgz': 3 } },

    // mathematics
    'mat': { value: 'Matematika', additional: { 'pgz': 2 } },
    'fiz': { value: 'Fizika', additional: { 'pgz': 2 } },
    'hem': { value: 'Hemija', additional: { 'pgz': 0 } },
    'inf': { value: 'Informatika', additional: { 'pgz': 2 } },

    // sciences
    'bio': { value: 'Biologija', additional: { 'pgz': 3 } },
    'his': { value: 'Historija', additional: { 'pgz': 3 } },
    'geo': { value: 'Geografija', additional: { 'pgz': 3 } },

    // religion  
    'vje': { value: 'Vjeronauka', additional: { 'pgz': 1 } },
    'eti': { value: 'Etika', additional: { 'pgz': 1 } },

    // culture
    'lik': { value: 'Likovno', additional: { 'pgz': 0 } },
    'muz': { value: 'Muzičko', additional: { 'pgz': 0 } },
    'tje': { value: 'Tjelesni', additional: null },
    'coz': { value: 'ČOZ', additional: null },

    // others
    'fil': { value: 'Filozofija', additional: { 'pgz': 1 } },
    'psi': { value: 'Psihologija', additional: { 'pgz': 1 } },
    'soc': { value: 'Sociologija', additional: { 'pgz': 1 } },
    'kul': { value: 'Kultura rel.', additional: { 'pgz': 1 } },

    'nacgeo': { value: 'Nacrt. geo.', additional: null },
};