module.exports = {
    'dark': {
        title: 'Dark',
        className: 'dark',
        mainColor: '#202225',
        statusBarStyle: 'dark',
        pricing: { value: 0 },
        accessories: {
            // peek: {
            //     position: 'periods',
            //     source: 'raspored',
            //     size: { width: 10, height: 10 },
            //     offsetY: 0,
            //     pricing: { value: 0 }
            // },
            // background: { source: require('../../base/accessories/backgrounds/attackontitan.png'), pricing: { value: 0 } },
        },
    },
    'light': {
        title: 'Light',
        className: 'light',
        mainColor: '#ffffff',
        statusBarStyle: 'light',
        pricing: { value: 0 },
        accessories: {},
    },
    'yellow': {
        title: 'Yellow',
        className: 'yellow',
        mainColor: '#ffffb2',
        statusBarStyle: 'light',
        pricing: { value: 2 },
        discount: { value: 0.33 },
        accessories: {},
    },
    'orange': {
        title: 'Orange',
        className: 'orange',
        mainColor: '#fce0c5',
        statusBarStyle: 'light',
        pricing: { value: 2 },
        discount: { value: 0.33 },
        accessories: {
            peek: {
                position: 'periods',
                source: require('../../base/accessories/peeks/fox.gif'),
                // source: require('../../base/accessories/peeks/foxspin.gif'),
                size: { width: 55, height: 55 },
                offsetY: -5,
                pricing: { value: 1 }
            },
        },
    },
    'beige': {
        title: 'Beige',
        className: 'beige',
        mainColor: '#ffe1f3',
        statusBarStyle: 'light',
        pricing: { value: 2 },
        discount: { value: 0.5 },
        accessories: {},
    },
    'brown': {
        title: 'Brown',
        className: 'brown',
        mainColor: '#e8d5c0',
        statusBarStyle: 'light',
        pricing: { value: 2 },
        accessories: {
            peek: {
                position: 'periods',
                source: require('../../base/accessories/peeks/bubu.gif'),
                size: { width: 65, height: 65 },
                offsetY: -5,
                pricing: { value: 1 }
            },
        },
    },
    'salmon': {
        title: 'Salmon',
        className: 'salmon',
        mainColor: '#fcd3cf',
        statusBarStyle: 'light',
        pricing: { value: 2 },
        discount: { value: 0.33 },
        accessories: {},
    },
    'ruby': {
        title: 'Ruby',
        className: 'ruby',
        mainColor: '#e6cbcb',
        statusBarStyle: 'light',
        pricing: { value: 2 },
        accessories: {},
    },
    'babypink': {
        title: 'Baby Pink',
        className: 'babypink',
        mainColor: '#fddee4',
        statusBarStyle: 'light',
        pricing: { value: 2 },
        discount: { value: 0.33 },
        accessories: {
            peek: {
                position: 'periods',
                source: require('../../base/accessories/peeks/peach.gif'),
                size: { width: 60, height: 60 },
                offsetY: -7,
                pricing: { value: 1 }
            },
        },
    },
    'purple': {
        title: 'Purple',
        className: 'purple',
        mainColor: '#ead2f6',
        statusBarStyle: 'light',
        pricing: { value: 2 },
        accessories: {
            peek: {
                position: 'periods',
                source: require('../../base/accessories/peeks/goma.gif'),
                size: { width: 50, height: 50 },
                offsetY: -5,
                pricing: { value: 1 }
            },
        },
    },
    'sky': {
        title: 'Sky',
        className: 'sky',
        mainColor: '#cadfec',
        statusBarStyle: 'light',
        pricing: { value: 2 },
        discount: { value: 0.5 },
        accessories: {},
    },
    'lapis': {
        title: 'Lapis',
        className: 'lapis',
        mainColor: '#dddffd',
        statusBarStyle: 'light',
        pricing: { value: 2 },
        discount: { value: 0.5 },
        accessories: {},
    },
    'mint': {
        title: 'Mint',
        className: 'mint',
        mainColor: '#d0f6da',
        statusBarStyle: 'light',
        pricing: { value: 2 },
        discount: { value: 0.33 },
        accessories: {
            peek: {
                position: 'periods',
                source: require('../../base/accessories/peeks/matcha.gif'),
                size: { width: 70, height: 60 },
                offsetY: -3,
                pricing: { value: 1 }
            },
        },
    },
    'lime': {
        title: 'Lime',
        className: 'lime',
        mainColor: '#d6ffae',
        statusBarStyle: 'light',
        pricing: { value: 2 },
        accessories: {},
    },

    /* special themes */
    'banana': {
        title: 'Banana',
        className: 'yellow',
        mainColor: '#2a292f',
        statusBarStyle: 'light',
        hidden: false,
        pricing: { value: 2 },
        discount: { value: 0.33 },
        accessories: {
            background: {
                source: require('../../base/accessories/backgrounds/banana.png'),
                type: 'pattern',
                blurTimetable: false,
                pricing: { value: 1 },
            },
        },
    },
	
    'spiritedaway': {
        title: 'Spirited Away',
        className: 'spiritedaway',
        mainColor: '#2a292f',
        statusBarStyle: 'dark',
        hidden: true,
        pricing: { value: 2 },
        discount: { value: 0.33 },
        accessories: {
            background: {
                source: require('../../base/accessories/backgrounds/spiritedaway.png'),
                type: 'full',
                blurTimetable: true,
                pricing: { value: 1 },
            },
        },
    },
    'attackontitan': {
        title: 'Attack On Titan',
        className: 'attackontitan',
        mainColor: '#000000',
        statusBarStyle: 'light',
        hidden: true,
        pricing: { value: 2 },
        accessories: {
            background: {
                source: require('../../base/accessories/backgrounds/attackontitan.png'),
                type: 'full',
                blurTimetable: true,
                pricing: { value: 1 },
            },
        },
    },
    'brba': {
        title: 'Sky',
        className: 'sky blur',
        mainColor: '#cadfec',
        statusBarStyle: 'light',
        pricing: { value: 2 },
        hidden: true,
        accessories: {
            background: {
                source: require('../../base/accessories/backgrounds/brba.png'),
                type: 'full',
                blurTimetable: true,
                pricing: { value: 1 },
            }
        },
    },
}