import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import './index.css'; // imports the main stylesheet file
import App from  './App'; // imports the main App component, for displaying purposes, of course

ReactDOM.render(
    <>
        <App /> {/* <-- main App component */}
    </>, document.getElementById('root')
);

serviceWorkerRegistration.register(); // registers the service worker for offline use
reportWebVitals(); // reports the web vitals to Google Analytics