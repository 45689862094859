import React from 'react';
import { SwitchTransition, CSSTransition, TransitionGroup } from "react-transition-group";

import RadioButton from '../elements/RadioButton';
import HybridButton from '../elements/HybridButton';

import averageGrades from '../../utility/averageGrades';
import truncateString from '../../utility/truncateString';

export default class ProfilesContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayedContentMode: 'list',
            profileBeingEdited: null,

            nameBeingEdited: false,
        };
    }

    render() {
        return (
            <SwitchTransition>
                <CSSTransition key={this.state.displayedContentMode}
                    addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                    classNames='content-item'>

                    {(() => {
                        switch(this.state.displayedContentMode) {
                            case 'list':
                                return (
                                    <div className='modal-content'>
                                        <TransitionGroup className="radio-button-container">
                                            {this.props.profiles.map((profile, index) => {
                                                return (
                                                    <CSSTransition key={profile.identifier} timeout={500} classNames="root-transition-item">
                                                        <RadioButton onClick={() => {
                                                            this.props.setProfile(profile.identifier);
                                                            this.props.setParentState({ displayModal: false });
                                                        }} name={profile.metadata.name || `Profil #${index + 1}`} tooltip={`(${averageGrades(profile.subjects.map((subject) => averageGrades(subject.grades.map((grade) => grade.value), false, false, true)), true, true, true)})`} value={this.props.userProfile === profile.identifier}
                                                        onCogClick={() => this.setState({ displayedContentMode: 'edit', profileBeingEdited: index })} />
                                                    </CSSTransition>
                                                );
                                            })}

                                            <div className='classes-add-button' onClick={this.props.addProfile}>
                                                <svg viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M25.25 19H19V25.25C19 25.9375 18.4375 26.5 17.75 26.5C17.0625 26.5 16.5 25.9375 16.5 25.25V19H10.25C9.5625 19 9 18.4375 9 17.75C9 17.0625 9.5625 16.5 10.25 16.5H16.5V10.25C16.5 9.5625 17.0625 9 17.75 9C18.4375 9 19 9.5625 19 10.25V16.5H25.25C25.9375 16.5 26.5 17.0625 26.5 17.75C26.5 18.4375 25.9375 19 25.25 19Z" />
                                                </svg>
                                            </div>
                                        </TransitionGroup>
                                    </div>
                                );
                            case 'edit':
                                return (
                                    <div className='modal-content'>
                                        <div className='modal-header'>
                                            <div style={{ position: 'relative', cursor: 'pointer' }} onClick={() => this.setState({ displayedContentMode: 'list', profileBeingEdited: null })}>
                                                {/* back arrow */}
                                                <svg className='modal-header-back' viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.80545 11.65L0.391265 6.80715C0.250737 6.68145 0.150154 6.55144 0.0895149 6.41713C0.0298386 6.28282 0 6.14378 0 6C0 5.85622 0.0298386 5.71718 0.0895149 5.58287C0.150154 5.44856 0.250737 5.31855 0.391265 5.19285L5.80545 0.349978C6.06533 0.116659 6.36612 0 6.70781 0C7.04855 0 7.34933 0.116659 7.61018 0.349978C7.87006 0.583297 8 0.852346 8 1.15712C8 1.4619 7.87006 1.73095 7.61018 1.96427L3.09836 6L7.61018 10.0357C7.87006 10.269 8 10.5381 8 10.8429C8 11.1477 7.87006 11.4167 7.61018 11.65C7.34933 11.8833 7.04855 12 6.70781 12C6.36612 12 6.06533 11.8833 5.80545 11.65Z" />
                                                </svg>

                                                {/* title */}
                                                <p className='modal-title'>{this.props.profiles[this.state.profileBeingEdited].metadata.name || `Profil #${this.state.profileBeingEdited + 1}`}</p>
                                            </div>
                                        </div>

                                        <div className='hybrid-button-container'>

                                            {/* edit name */}
                                            <HybridButton displayOutline={this.state.nameBeingEdited} outlineColor='var(--indicator-color)'
                                                onClick={() => {
                                                    if (this.state.nameBeingEdited) this.setState({ nameBeingEdited: false });
                                                    else this.setState({ nameBeingEdited: true });
                                                }}
                                                
                                                hybrid={true} displayInput={this.state.nameBeingEdited}
                                                inputPlaceholder='Naziv' inputDefault={this.props.profiles[this.state.profileBeingEdited].metadata.name || ''}
                                                
                                                onChange={(event) => {
                                                    let newValue = event.target.value;
                                                        newValue = newValue.slice(0, 25); // limit to 25 characters

                                                    if (event.target.value.endsWith(' ')) newValue = newValue.trim() + ' '; // adds a trailing space if the user presses a space after the last character
                                                    else newValue = newValue.trim(); // else just trims without the trailing space

                                                    event.target.value = newValue;
                                                    this.props.editProfileName(this.props.profiles[this.state.profileBeingEdited].identifier, newValue.trim());
                                                }}

                                                iconType='edit' iconFill='var(--indicator-color)' rotateIcon={this.state.nameBeingEdited}
                                                buttonText='Promijeni naziv' buttonTooltip={truncateString(this.props.profiles[this.state.profileBeingEdited].metadata.name || '', 10)} />

                                            {/* clear grades */}
                                            <HybridButton onClick={() => { this.props.clearProfile(this.props.profiles[this.state.profileBeingEdited].identifier); this.setState({ displayedContentMode: 'list', profileBeingEdited: null, nameBeingEdited: false }); }}
                                                buttonText='Očisti' buttonTooltip='(ocjene)'
                                                iconType='clear' iconFill="var(--destructive-color)" style={{ width: '49%' }} />

                                            {/* clear everything */}
                                            <HybridButton onClick={() => { this.props.clearProfile(this.props.profiles[this.state.profileBeingEdited].identifier, true); this.setState({ displayedContentMode: 'list', profileBeingEdited: null, nameBeingEdited: false }); }}
                                                buttonText='Očisti' buttonTooltip='(sve)'
                                                iconType='clear' iconFill="var(--destructive-color)" style={{ width: '49%' }} />

                                            {/* delete, disabled if there is only one profile in the array */}
                                            {this.props.profiles.length === 1 || (
                                                <HybridButton onClick={() => { this.props.deleteProfile(this.props.profiles[this.state.profileBeingEdited].identifier); this.setState({ displayedContentMode: 'list', profileBeingEdited: null, nameBeingEdited: false }); }}
                                                    buttonText='Izbriši'
                                                    iconType='delete' iconFill="var(--destructive-color)" />
                                            )}
                                        </div>
                                    </div>
                                );
                            default:
                                return <p>oops</p>;
                        }
                    })()}
                </CSSTransition>
            </SwitchTransition>
        );
    }
}