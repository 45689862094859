import React from 'react';
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { SpinnerCircularFixed } from 'spinners-react';

import Server from '../../backend/Server';

export default class NewTableContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fetchingTable: true,
            fetchedTable: null,
        };
    }

    componentDidMount = () => {
        Server.getTableShareData(this.props.tableLaunchCode).then((response) => {
            this.setState({ fetchingTable: false, fetchedTable: response.data }); // set the fetching table state to false and the fetched table state to the received data!

        }).catch((response) => {
            console.error(response);
            this.setState({ fetchingTable: false }); // set the fetchingTable state to false, displaying the error message
        });
    }

    render() {
        return (
            <SwitchTransition>
                <CSSTransition key={this.state.fetchingTable}
                    addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                    classNames='content-item'>

                    {!this.state.fetchingTable ? (
                        <div className='modal-content'>
                            {this.state.fetchedTable ? (
                                <>
                                    <div className='modal-header' style={{ justifyContent: 'center' }}>
                                        <p style={{ fontSize: '26px', textAlign: 'center', color: 'var(--text-primary)', fontWeight: 'bold' }}>{this.state.fetchedTable.name || 'Custom raspored'}</p>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className='pressable' style={{ width: '100px', padding: '10px', borderRadius: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px', fontWeight: 'bold', color: 'var(--text-primary)', backgroundColor: 'var(--background-tertiary)' }}
                                            onClick={() => { this.props.addCustomTable(this.state.fetchedTable, true); this.props.setParentState({ modalContent: 'tables', modalContentPath: 'custom' }); }}>

                                            Dodaj
                                        </div>
                                        <div className='pressable' style={{ padding: '10px', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px', color: 'var(--text-secondary)' }}
                                            onClick={() => this.props.setParentState({ displayModal: false })}>

                                            Ne, hvala
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ padding: '10px', textAlign: 'center', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'var(--text-secondary)' }}>
                                        Nismo uspjeli da pronađemo Vaš raspored, molimo da pokušate ponovo.
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='modal-content'>
                            <div className='modal-header' style={{ justifyContent: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <SpinnerCircularFixed size='25px' thickness={200} color='var(--text-primary)' secondaryColor="var(--background-tertiary)" />
                                </div>
                            </div>
                        </div>
                    )}
                </CSSTransition>
            </SwitchTransition>
        );
    }
}