import React from 'react';

import '../../stylesheet/themes.css';

export default class ThemeItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        switch (this.props.type || 'classic') {
            case 'classic':
                return (
                    <div style={{ position: 'relative' }}>
                        {this.props.discount ? (
                            <div className='theme-discount-container' style={{
                              position: 'absolute',
                              left: '-8px',
                              top: '-6px', 
                            }}>
                                <p className='theme-discount'>-{this.props.discount.value * 100}%</p>
                            </div>
                        ) : null}

                        <div className={`theme-item dark ${this.props.className}`} onClick={this.props.onClick}
                            style={this.props.selected ? { transform: 'scale(1.05)', filter: 'drop-shadow(rgba(0, 0, 0, 0.25) 0px 0px 7px)', opacity: this.props.owned ? 1 : .5 } : { opacity: this.props.owned ? 1 : .5 }}>
                            
                            <div></div>
                            <div></div>
                            <div></div>
            
                            {this.props.selected ? (
                                <svg width="54" height="54" className='theme-check' viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M37.3275 17.0547L22.5 31.8822L14.4225 23.8272L11.25 26.9997L22.5 38.2497L40.5 20.2497L37.3275 17.0547Z" />
                                </svg>
                            ) : null}
                            
                            {!this.props.owned ? (
                                <svg width="48" height="48" className='theme-check' viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5991 36C16.8802 36 16.2673 35.7374 15.7604 35.2123C15.2535 34.6872 15 34.0523 15 33.3076V23.6837C15 22.939 15.2535 22.2993 15.7604 21.7646C16.2673 21.23 16.8802 20.9626 17.5991 20.9626H18.4286V18.8431C18.4286 17.22 18.9677 15.8404 20.0461 14.7042C21.1244 13.5681 22.4378 13 23.9862 13C25.5346 13 26.8479 13.5681 27.9263 14.7042C29.0046 15.8404 29.5438 17.22 29.5438 18.8431V20.9626H30.3733C31.0922 20.9626 31.7097 21.23 32.2258 21.7646C32.7419 22.2993 33 22.939 33 23.6837V33.3076C33 34.0523 32.7419 34.6872 32.2258 35.2123C31.7097 35.7374 31.0922 36 30.3733 36H17.5991ZM21.0276 20.9626H26.9447V18.8431C26.9447 17.9456 26.6636 17.2009 26.1014 16.609C25.5392 16.017 24.8341 15.721 23.9862 15.721C23.1567 15.721 22.4562 16.017 21.8848 16.609C21.3134 17.2009 21.0276 17.9456 21.0276 18.8431V20.9626Z" />
                                </svg>
                            ) : null}

                            {this.props.special ? (
                                <svg width="17" height="17" className='theme-sparkle' viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.7845 5.66715L14.3441 4.42757L15.5836 3.86798C15.8599 3.74048 15.8599 3.3509 15.5836 3.2234L14.3441 2.66382L13.7845 1.41715C13.657 1.1409 13.2674 1.1409 13.1399 1.41715L12.5803 2.65673L11.3336 3.21632C11.0574 3.34382 11.0574 3.7334 11.3336 3.8609L12.5732 4.42048L13.1328 5.66715C13.2603 5.9434 13.657 5.9434 13.7845 5.66715ZM8.14615 6.72965L7.0199 4.25048C6.77198 3.69798 5.97865 3.69798 5.73073 4.25048L4.60448 6.72965L2.12531 7.8559C1.57281 8.1109 1.57281 8.89715 2.12531 9.14507L4.60448 10.2713L5.73073 12.7505C5.98573 13.303 6.77198 13.303 7.0199 12.7505L8.14615 10.2713L10.6253 9.14507C11.1778 8.89007 11.1778 8.10382 10.6253 7.8559L8.14615 6.72965ZM13.1328 11.3338L12.5732 12.5734L11.3336 13.133C11.0574 13.2605 11.0574 13.6501 11.3336 13.7776L12.5732 14.3371L13.1328 15.5838C13.2603 15.8601 13.6499 15.8601 13.7774 15.5838L14.337 14.3442L15.5836 13.7846C15.8599 13.6571 15.8599 13.2676 15.5836 13.1401L14.3441 12.5805L13.7845 11.3338C13.657 11.0576 13.2603 11.0576 13.1328 11.3338Z" />
                                </svg>
                            ) : null}
                        </div>
                    </div>
                );
            case 'list':
                return (
                    <div className='shop-item' style={{ opacity: (this.props.owned ? .5 : 1), cursor: (this.props.clickable ? 'pointer' : 'default') }} onClick={this.props.onClick}>
                        <div className='shop-item-information'>
                            <div className={`theme-preview dark ${this.props.className}`}>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
        
                            <p className='shop-item-name' style={{ fontSize: this.props.titleSize, width: (!this.props.displayEye ? 'auto' : null) }}>{this.props.title}</p>
                        </div>
        
                        {!this.props.displayEye || (
                            <svg className='shop-item-button' viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.0256 12.2706C12.3125 12.2706 13.4046 11.8133 14.3021 10.8988C15.1995 9.98426 15.6483 8.88357 15.6483 7.59672C15.6483 6.30906 15.1995 5.2165 14.3021 4.31903C13.4046 3.42157 12.3125 2.97284 11.0256 2.97284C9.73876 2.97284 8.63766 3.42157 7.72231 4.31903C6.80859 5.2165 6.35174 6.30906 6.35174 7.59672C6.35174 8.90064 6.80453 10.0054 7.71012 10.911C8.61652 11.8174 9.72169 12.2706 11.0256 12.2706ZM11.0256 10.3647C10.2631 10.3647 9.61113 10.094 9.06973 9.5526C8.52751 9.01038 8.2564 8.35842 8.2564 7.59672C8.2564 6.8342 8.52751 6.19037 9.06973 5.66523C9.61113 5.14008 10.2631 4.87751 11.0256 4.87751C11.7873 4.87751 12.4307 5.14008 12.9559 5.66523C13.481 6.19037 13.7436 6.8342 13.7436 7.59672C13.7436 8.35842 13.481 9.01038 12.9559 9.5526C12.4307 10.094 11.7873 10.3647 11.0256 10.3647ZM11 15.2678C8.62872 15.2678 6.46961 14.6415 4.52267 13.3888C2.57492 12.1352 1.10963 10.4501 0.126815 8.33322C0.0934855 8.24868 0.0638141 8.14259 0.0378007 8.01497C0.0126003 7.88815 0 7.75686 0 7.62111C0 7.48616 0.0126003 7.35528 0.0378007 7.22847C0.0638141 7.10084 0.0934855 6.99475 0.126815 6.91021C1.10963 4.79337 2.57898 3.11266 4.53486 1.86809C6.49074 0.622695 8.64579 0 11 0C13.3542 0 15.5093 0.622695 17.4651 1.86809C19.421 3.11266 20.8904 4.79337 21.8732 6.91021C21.9065 6.99475 21.9362 7.10084 21.9622 7.22847C21.9874 7.35528 22 7.48616 22 7.62111C22 7.75686 21.9874 7.88815 21.9622 8.01497C21.9362 8.14259 21.9065 8.24868 21.8732 8.33322C20.8904 10.4501 19.4251 12.1352 17.4773 13.3888C15.5304 14.6415 13.3713 15.2678 11 15.2678Z" />
                            </svg>
                        )}
                    </div>
                );
            default:
                return <p>oops</p>;
        }
    }
}