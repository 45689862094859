const configuration = {
    developerOptions: false,
    openSource: {
        app: [
		    '@capacitor-community/admob',
			'@capacitor-community/barcode-scanner',
			'@capacitor-community/media',
			'@capacitor/android',
			'@capacitor/app',
			'@capacitor/clipboard',
			'@capacitor/core',
			'@capacitor/device',
			'@capacitor/filesystem',
			'@capacitor/haptics',
			'@capacitor/keyboard',
			'@capacitor/share',
			'@capacitor/status-bar',
			'@capacitor/text-zoom',
			'@capacitor/toast',
			'@iarna/toml',
			'@testing-library/jest-dom',
			'@testing-library/react',
			'@testing-library/user-event',
			'axios',
			'file-saver',
			'html-to-image',
			'install',
			'moment',
			'npm',
			'qr-code-styling',
			'qrcode',
			'react',
			'react-beautiful-dnd',
			'react-dom',
			'react-easy-swipe',
			'react-scripts',
			'react-transition-group',
			'spinners-react',
			'uuid',
			'web-vitals',
			'workbox-background-sync',
			'workbox-broadcast-update',
			'workbox-cacheable-response',
			'workbox-core',
			'workbox-expiration',
			'workbox-google-analytics',
			'workbox-navigation-preload',
			'workbox-precaching',
			'workbox-range-requests',
			'workbox-routing',
			'workbox-strategies',
			'workbox-streams'
		],
        server: [
            'body-parser',
            'cors',
            'express',
            'mongodb',
            'voucher-code-generator'
        ],
    },
    additionalCredits: [
        'Some content/imagery used may belong to their respective Weibo authors.',
        'Icons used in the app are modified Google Material Symbols.',
    ],
    storeLink: 'https://play.google.com/store/apps/details?id=com.ibrahim.raspored'
};

module.exports = configuration;