import React from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import Swipe from 'react-easy-swipe';

import ContentSplash from "./ContentSplash";

import Application from '../../backend/configuration/Application';

import '../../stylesheet/structure/content.css';
import '../../stylesheet/alerts.css';
import '../../stylesheet/elements/contentSplash.css';

class AlertStar extends React.Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.56705 23.6274C5.91695 24.1124 5.26105 24.1239 4.59935 23.662C3.93764 23.2001 3.73448 22.588 3.98988 21.8259L6.07948 15.0702L0.751005 11.2594C0.0776901 10.7975 -0.142878 10.1854 0.089299 9.42323C0.321476 8.66106 0.843875 8.27997 1.6565 8.27997H8.27355L10.4328 1.1779C10.5257 0.785269 10.7288 0.490793 11.0423 0.294476C11.3557 0.0981584 11.675 0 12 0C12.325 0 12.6443 0.0981584 12.9577 0.294476C13.2712 0.490793 13.4859 0.785269 13.602 1.1779L15.7264 8.27997H22.3435C23.1561 8.27997 23.6785 8.66106 23.9107 9.42323C24.1429 10.1854 23.9223 10.7975 23.249 11.2594L17.9205 15.0702L20.0101 21.7912C20.2655 22.5765 20.0624 23.1943 19.4007 23.6447C18.7389 24.0951 18.0947 24.0777 17.4678 23.5927L12.0348 19.5047L6.56705 23.6274Z" />
            </svg>
        );
    }
}

export default class AlertItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            slideList: [0, 1, 2, 3, 4],
            currentSlide: 0,
        }
    }

    switchSlide(increment = 1) {
        if (increment === -1 && this.state.currentSlide === 0) return this.setState({ currentSlide: this.state.slideList.length - 1 }); // if the user tries to go to the previous slide while on the first slide, switch to the last slide in array
        this.setState({ currentSlide: ((this.state.currentSlide + increment) % this.state.slideList.length) }); // modulo to avoid overflow
    }

    onSwipeLeft = () => { this.switchSlide(); }
    onSwipeRight = () => { this.switchSlide(-1); }

    render() {
        switch (this.props.type) {
            case 'review':
                return (
                    <div className='alert'>
                        <div className="content-splash">
                            <ContentSplash type='star' />
                        </div>

                        <p style={{ fontSize: '22px', fontWeight: 'bold' }}>Sviđa li Vam se Raspored?</p>
                        <p style={{ marginTop: '8px' }}>
                            Pritisnite ovdje da ocijenite aplikaciju<br />
                            na Google Play trgovini.
                        </p>
                    
                        <div className='alert-stars' onClick={() => window.open(Application.storeLink, '_blank')}>
                            <AlertStar />
                            <AlertStar />
                            <AlertStar />
                            <AlertStar />
                            <AlertStar />
                        </div>
                    </div>
                );
            case 'introduction':
                return (
                    <div className='alert'>
                        <p style={{ fontSize: '22px', fontWeight: 'bold' }}>Dobro došli! 👋</p>
                        <p style={{ marginTop: '8px' }}>
                            Da li želite znati više o Rasporedu? Imamo nekoliko stvari koje biste trebali znati, pa započnimo!
                        </p>
                    
                        <Swipe onSwipeRight={this.onSwipeRight}
                            onSwipeLeft={this.onSwipeLeft}
                            className='alert-carousel'>

                            <SwitchTransition>
                                <CSSTransition key={this.state.currentSlide}
                                    addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                                    classNames='content-item'>

                                    {/* alert carousel image */}
                                    <div className='alert-carousel-image' style={{ backgroundImage: `url(${require(`../../base/introduction/${this.state.currentSlide}.png`).default})` }} onClick={() => this.switchSlide()}></div>
                                </CSSTransition>
                            </SwitchTransition>

                            <div className='alert-carousel-pages'>
                                {this.state.slideList.map((slide, index) => { // map through all slides
                                    return <div key={index} className={slide === this.state.currentSlide ? "alert-carousel-pages-current" : ""}></div>;
                                })}
                            </div>
                        </Swipe>
                    </div>
                );
            case 'version':
                return (
                <div className='alert' onClick={() => {}} style={{ cursor: 'pointer', boxSizing: 'border-box', paddingBottom: '15px' }}>
                        <div className="content-splash">
                            <ContentSplash type='star' />
                        </div>

                        <p style={{ fontSize: '22px', fontWeight: 'bold' }}>Nova verzija je dostupna!</p>
                        <p style={{ marginTop: '8px' }}>
                            Pritisnite <span style={{ textDecoration: 'underline' }}>ovdje</span> kako biste ažurirali aplikaciju na Google Play trgovini!
                        </p>
                    </div>
                );
            default:
                return <p>oops</p>;
        }
    }
}