import React from 'react';

import averageGrades from '../../utility/averageGrades';

import '../../stylesheet/subjects.css';

export default class SubjectItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className='subject-item' key={this.props.index} onClick={this.props.onClick}>
                <p className='subject-item-name'>{this.props.name || `Predmet #${this.props.index + 1}`}</p>
                <p className='subject-item-grades'>{this.props.grades.map((grade) => grade.value).join(', ')}</p>
                
                <div className='subject-item-additional'>
                    <p className='subject-item-average'>
                        ({averageGrades(this.props.grades.map((grade) => grade.value))})
                    </p>

                    <svg className='subject-item-arrow' width="9" height="14" viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.645 0L0 1.645L5.34333 7L0 12.355L1.645 14L8.645 7L1.645 0Z" />
                    </svg>
                </div>
            </div>
        );
    }
}