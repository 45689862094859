import React from 'react';

import '../../stylesheet/accessories.css';

export default class AccessoryIcon extends React.Component {
    render() {
        // accessory type code
        switch (this.props.type) {
            case 'peek':
                // peek position switch code
                switch (this.props.data.position) {
                    case 'top':
                        return (
                            <>
                                <div className='peek-top-container'>
                                    <img className='peek-top'
                                        src={this.props.data.source.default} alt=''
                                        style={{ ...(this.props.data.size ? { width: this.props.data.size.width, height: this.props.data.size.height } : null) }}></img>
                                </div>
                            </>
                        );
                    case 'periods':
                        return (
                            <>
                                <div className='peek-periods-container' style={{ ...(this.props.data.size ? { width: this.props.data.size.width } : null), position: 'relative' }}>
                                    <img className='peek-periods'
                                        src={this.props.data.source.default} alt=''
                                        style={{ ...(this.props.data.size ? { width: this.props.data.size.width, height: this.props.data.size.height } : null), ...(this.props.data.offsetY ? { bottom: this.props.data.offsetY } : null) }}></img>
                                </div>
                            </>
                        );
                    default:
                        return null;
                }
            default:
                return null;
        }
    }
}