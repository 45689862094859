import React from "react";

import ButtonIcons from "../icons/ButtonIcons";

import '../../stylesheet/elements/hybridButton.css';

export default class HybridButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <div className={`hybrid-button ${this.props.additionalClassName}`} style={{ boxShadow: ((this.props.displayOutline && this.props.outlineColor) ? `0px 0px 0px 3px ${this.props.outlineColor} inset` : null), width: (this.props.buttonSize === 'small' ? '49%' : null), ...this.props.style }} onClick={(event) => this.props.onClick(event)}>

                <div>
                    {(this.props.displayInput && this.props.hybrid) ? (
                        <input className='hybrid-button-name hybrid-button-input' type="text" maxLength={this.props.inputMaxLength || 25} autoFocus placeholder={this.props.inputPlaceholder} defaultValue={this.props.inputDefault}
                            onChange={(event) => this.props.onChange(event)} onKeyDown={(event) => !this.props.onKeyDown || this.props.onKeyDown(event)}></input>
                    ) : (
                        <>
                            <p className='hybrid-button-name'>{this.props.buttonText}</p>
                            <p className='hybrid-button-tooltip'>{this.props.buttonTooltip}</p>
                        </>
                    )}
                </div>

                <ButtonIcons type={this.props.iconType} fill={this.props.iconFill} rotateIcon={this.props.displayInput} />
            </div>
        );
    }
}